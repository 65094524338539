<div class="container py-5">
  <div class="headerPage">
    <h1 class="main-title" [style.color]="labelColor">
      {{ pageTitle }}
    </h1>
    <app-button
      id="back-desktop"
      [onlyIcon]="true"
      [isIcon]="true"
      [left]="true"
      [iconName]="'bi bi-arrow-left'"
      [buttonOptions]="buttonBackOptions"
      (buttonClick)="back()"
    >
    </app-button>
  </div>

  <div class="card">
    <img src="/assets/imagens/everyone-card-banner.png"/>
  </div>

  <div class="mt-3">
    <app-button
      id="back-desktop"
      [buttonOptions]="buttonAdesaoOptions"
      (buttonClick)="solicitarAdesao()"
    >
    </app-button>
  </div>

</div>
