<div class="px-4 py-5">
    <div class="headerPage">
        <h1 class="main-title" [style.color]="labelColor">
            Experiências
        </h1>
        <div class="balance-title">
            <h2>Saldo: <span class="points color-primary">{{score}} pontos</span></h2>
            <app-button id="back-desktop" [onlyIcon]="true"
            [isIcon]="true" [left]="true"
            [iconName]="'bi bi-arrow-left'"
            [buttonOptions]="backOptions"
            (buttonClick)="back()">
        </app-button>
        </div>
        
    </div>
    <app-cards-resgatar
        typeContent="EXPERIENCE"
        [data]="items"
        [score]="score"
    ></app-cards-resgatar>
    <app-cards-palpites
        title="Palpites dos jogos"
        [data]="gameList"
        [logoUrl]="logoUrl"
        (cardAction)="openModalGuess($event)"
    ></app-cards-palpites>
    <app-cards-resgatar
        typeContent="EXPERIENCE"
        title="Experiências resgatadas"
        [data]="itemsExperienciasResgatadas"
    ></app-cards-resgatar>
</div>

<app-guess-modal
    [matchModalData]="matchModalData"
    (confirmActionChange)="onModalConfirm($event)"
></app-guess-modal>
