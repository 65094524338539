import { Component, EventEmitter, input, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/core/services/alert.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { WaveService } from 'src/app/core/services/wave.service';
import { AppInputCustomComponent, InputMaskTypes } from '../app-input-custom/app-input-custom.component';
import { AppInputComponent, InputType } from '../app-input/app-input.component';
import { AppButtonComponent } from "../app-button/app-button.component";
import { GuestService } from 'src/app/core/services/guest.service';

declare var bootstrap: any;

@Component({
  selector: 'app-guest-modal',
  standalone: true,
  imports: [AppInputComponent, AppInputCustomComponent, ReactiveFormsModule, AppButtonComponent],
  templateUrl: './app-guest-modal.component.html',
  styleUrl: './app-guest-modal.component.scss'
})
export class AppGuestModalComponent {

  modalId = input.required<string>();
  modalTitle = input<string>('Adicionar Convidado');
  modalInstance: any;
  modalConfirmLabel = input<string>('');
  modalCancelLabel = input<string>('');

  InputTypeEnum = InputType;
  InputMaskTypesEnum = InputMaskTypes;

  guestForm = new FormGroup({
    idControl: new FormControl<string>(''),
    nameControl: new FormControl<string>('', [Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ]{3,}( {1,2}[a-zA-ZÀ-ÿ]{2,}){1,}$')]),
    cpfControl: new FormControl<string>('', Validators.required),
    emailControl: new FormControl<string>('', [Validators.required, Validators.email])
  });

  confirmBtn = {
    buttonText: 'Confirmar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-5 py-2 btn-secondary',
  };

  cancelBtn = {
    buttonText: 'Cancelar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-5 py-2 btn-secondary',
  };

  private modalSubscription: Subscription | undefined;

  @Output() onModalGuestConfirm = new EventEmitter();

  constructor(private modalService: ModalService,
    private guestService: GuestService,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.modalSubscription = this.modalService.modalState$.subscribe((state) => {
      if(state.id === 'modalGuest'){
      const modalElement = document.getElementById(state.id);
      if (modalElement) {
        if (state.action === 'open') {
          this.modalInstance = new bootstrap.Modal(modalElement); // Instanciando o modal
          this.modalInstance.show();
          modalElement.addEventListener('hidden.bs.modal', () => {
            this.guestForm.reset();
          });
        } else if (state.action === 'close') {
          if (this.modalInstance) {
            this.modalInstance.hide();
          }
        }
      }
    }

    });

    if (this.modalId() === 'modalGuest') {
    }

  }

  closeModal() {
    this.guestForm.reset();
    if (this.modalInstance) {
      this.modalInstance.hide();
    }
  }


  get controls(): any{
    return this.guestForm.controls;
  }

  onConfirm() {
    const data = this.guestForm.getRawValue();
    if(this.guestForm.invalid){
      return
    }
    this.onModalGuestConfirm.emit(data);
    this.modalInstance.hide();
    this.guestForm.reset();
  }

  onChangeCpf(){
    let cpf = this.guestForm.get('cpfControl')?.value;
    if(cpf && cpf.length == 11){
      this.guestService.findGuestByCpf(cpf).subscribe({
        next : (res:any) => {
          console.log(res)
          this.guestForm.get('nameControl')?.setValue(res?.name)
          this.guestForm.get('emailControl')?.setValue(res?.email)
          this.guestForm.get('idControl')?.setValue(res?.id)
        }
      })
    }

  }


}
