import { Component } from '@angular/core';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { Location } from '@angular/common';
import { AppCheckboxComponent } from 'src/app/shared/components/app-checkbox/app-checkbox.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MemberService } from 'src/app/core/services/member.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { CommonModule } from '@angular/common';
import { MemberPlanDetailModel } from 'src/app/shared/models/planModel';

@Component({
  selector: 'app-edit-plan',
  standalone: true,
  imports: [AppButtonComponent, AppCheckboxComponent, ReactiveFormsModule, CommonModule],
  templateUrl: './edit-plan.component.html',
  styleUrl: './edit-plan.component.scss'
})
export class EditPlanComponent {

  currentUser: any;
  title = 'Editar Plano';
  invoiceRecurrenceControl = new FormControl<boolean>(false);
  currentUserData: any;
  showRenewalMessage: any = true;
  currentMemberPlanId: string | null | undefined = null; 


  btnSaveOptions = {
    buttonText: 'Salvar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm py-1 px-3 btn-primary',
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000'
  };

  buttonBackOptions = {
    buttonText: 'Voltar',
    buttonSize: 'btn btn-md px-3 btn-secondary',
    borderRadius: '25px',
  };

  constructor(
    private location: Location,
    private memberService: MemberService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.currentUserData = this.localStorageService.getItem('current-user');

    if (this.currentUserData) {
      if (this.currentUserData.memberPlans.length > 0) {
        this.memberService.getCurrentPlan(this.currentUserData.id).subscribe({
          next: (res: MemberPlanDetailModel) => {
            this.invoiceRecurrenceControl.setValue(res.automaticRenewal);
            this.currentMemberPlanId = res.id; 
          },
          error: (error: any) => {
            console.log('Error: ', error);
          }
        });
      } else {
        console.warn('Nenhum plano de membro encontrado.');
      }
    } else {
      console.error('Dados do usuário não encontrados no local storage.');
    }
  }

  onCheckboxChange() {
    this.showRenewalMessage = this.invoiceRecurrenceControl.value;
  }

  save() {
    const automaticRenewal = this.invoiceRecurrenceControl.value ?? false; 

    if (this.currentMemberPlanId) {
      this.memberService.updateAutomaticRenewal(this.currentMemberPlanId, automaticRenewal).subscribe({
        next: (response) => {
          console.log('Atualização bem-sucedida:', response);
        },
        error: (error) => {
          console.error('Erro ao atualizar:', error);
        }
      });
    } else {
      console.error('ID do plano atual não encontrado.');
    }
  }
  back(): void {
    this.location.back();
  }
}
