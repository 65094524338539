import { Component } from '@angular/core';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { Location } from '@angular/common';
import { AppCheckboxComponent } from 'src/app/shared/components/app-checkbox/app-checkbox.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MemberService } from 'src/app/core/services/member.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { CommonModule } from '@angular/common';
import { UserService } from 'src/app/core/services/user.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delete-account',
  standalone: true,
  imports: [AppButtonComponent, AppCheckboxComponent, ReactiveFormsModule, CommonModule],
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss']
})
export class DeleteAccountComponent {

  currentUser: any;
  title = 'Excluir Conta';
  confirmDeletionControl = new FormControl<boolean>(false);
  currentUserData: any;
  showDeletionMessage: any = false;

  btnDeleteOptions = {
    buttonText: 'Excluir Conta',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm py-1 px-3 btn-danger',
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000'
  };

  btnCancelOptions = {
    buttonText: 'Cancelar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm py-1 px-3 btn-secondary',
  };

  constructor(
    private location: Location,
    private memberService: MemberService,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private alertService: AlertService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.currentUserData = this.localStorageService.getItem('current-user');
    if (!this.currentUserData) {
      console.error('Dados do usuário não encontrados no local storage.');
    }
  }

  onCheckboxChange() {
    this.showDeletionMessage = this.confirmDeletionControl.value;
  }

  deleteAccount() {
    if (this.confirmDeletionControl.value) {
      this.memberService.deleteMember(this.currentUserData.id).subscribe({
        next: (response) => {
          // Altera o status do usuário para INACTIVE
          this.userService.changeUserStatus(this.currentUserData.cpf, 'INACTIVE').subscribe({
            next: (statusResponse) => {
              this.alertService.showAlert(AlertType.SUCCESS, 'Conta excluida com sucesso!');
                    setTimeout(() => {
                      this.logout();
                    }, 1500);
              
            },
            error: (statusError) => {
              console.error('Erro ao alterar o status do usuário:', statusError);
            }
          });
        },
        error: (error) => {
          console.error('Erro ao excluir a conta:', error);
        }
      });
    } else {
      console.error('Confirmação de exclusão não marcada.');
    }
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['/']);
  }

  cancel() {
    this.location.back();
  }
}