<div class="container py-5">
    <div class="headerPage">
        <h1 class="main-title" [style.color]="labelColor">
            Ingressos
        </h1>
        <app-button id="back-desktop" [onlyIcon]="true" [isIcon]="true" [left]="true" [iconName]="'bi bi-arrow-left'"
        [buttonOptions]="buttonBackOptions" (buttonClick)="back()">
    </app-button>
    </div>
    @if (showDiv) {
        <div class="d-grid d-md-flex flex-wrap">
            @for (ticket of ingressosCheckIn; track $index) {
                <div class="col-12 col-lg-6 col-xl-4">
                    <app-ticket-card [ticket]="ticket" [memberPlan]="memberPlan" [memberData]="memberData" (btnCheckInAction)="openCheckInModal($event)"></app-ticket-card>
                </div>
            }
            @if (ingressosCheckIn.length === 0) {
                <b>
                    Não existem jogos para realização de check-in ou compra de ingressos.
                </b>
            }
        </div>
    }

    <!-- <div class="historico">
        <h2>Histórico de Check-in</h2>

        <p class="main-subtitle" [style.color]="labelColor" *ngIf="ingressosUsados.length == 0 && ingressosNaoUsados.length == 0">
            Não existem ingressos para serem exibidos.
        </p>

        <h3 class="mt-5 mb-3">Jogos que eu fui</h3>
        <div class="d-grid d-md-flex flex-wrap">
            @for (ticket of ingressosUsados; track $index) {
                <div class="col-12 col-lg-6 col-xl-4">
                    <app-ticket-card [ticket]="ticket" [hasCheckinBtn]="false" [hasTicketDetails]="true"></app-ticket-card>
                </div>
            }
        </div>

        <h3 class="mt-5 mb-3">Jogos que eu não fui</h3>
        <div class="d-grid d-md-flex flex-wrap">
            @for (ticket of ingressosNaoUsados; track $index) {
                <div class="col-12 col-lg-6 col-xl-4">
                    <app-ticket-card [ticket]="ticket" [hasCheckinBtn]="false" [hasTicketDetails]="true"></app-ticket-card>
                </div>
            }
        </div>
    </div> -->
</div>

<app-form-modal
    [modalId]="modalId"
    [modalTitle]="'Realizar Check-in'"
    [showCloseBtn]="false"
    [ticketData]="ingressosCheckIn"
    [checkinsHistory]="checkinsHistory"
    [limitCheckin]="limitCheckin"
    [checkinSelected]="checkinSelected"
    [selectInputOptions]="selectInputOptionsModal"
    [selectControl]="selectControlModal"
    (onModalConfirm)="onModalConfirm($event)"
></app-form-modal>

<app-confirm-modal [modalId]="'modalConfirm'" [link]="linkModalConfirm"></app-confirm-modal>
