<div class="container p-4 p-md-5 col-md-7 update-password-page">
    <div class="d-flex justify-content-end">
        <app-button id="back-desktop" [onlyIcon]="true"
            [isIcon]="true" [left]="true"
            [iconName]="'bi bi-arrow-left'"
            [buttonOptions]="buttonOptions"
            [buttonType]="'btn-secondary'"
            (buttonClick)="back()">
        </app-button>
    </div>
    <div id="update-pass-title" class="update-pass-main-title my-4">
        {{title}}
    </div>
    <div id="update-pass-subtitle" class="update-pass-sub-title mt-3 mb-4">
        {{subtitle}}
    </div>
    <form [formGroup]="updatePassFormGroup">
        <div class="row mb-5">
            <app-input class="col"
                [id]="'currentPasswordInputId'"
                [label]="'Senha atual'"
                [type]="InputTypeEnum.PASSWORD"
                [placeholder]="'Insira a sua senha atual'"
            ></app-input>
            <a class="password-forgot color-primary" routerLink="/login/recuperar-senha">Esqueceu sua senha ?</a>
        </div>
        <div class="row">
            <app-input class="col"
                [id]="'newPasswordInputId'"
                [label]="'Nova senha'"
                [type]="InputTypeEnum.PASSWORD"
                [placeholder]="'Crie uma nova senha'"
                [maxLength]="12"
                formControlName="newPassControl"
            ></app-input>
        </div>
        <div class="row">
            <app-input class="col"
                [id]="'repeatNewPasswordInputId'"
                [label]="'Confirmar senha'"
                [type]="InputTypeEnum.PASSWORD"
                [placeholder]="'Confirme sua nova senha'"
                [maxLength]="12"
                formControlName="confirmNewPassControl"
            ></app-input>
        </div>
        <div class="d-flex justify-content-end mt-4">
            <app-button class="my-2 mx-2"
                [disabled]="updatePassFormGroup.invalid"
                [buttonOptions]="btnSaveOptions"
                (buttonClick)="onSubmit()">
            </app-button>
        </div>
    </form>
</div>
