import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from './error-handler.service';
import { UserService } from './user.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WaveService extends BaseService {

  constructor(http: HttpClient,
    private userService: UserService,
    errorHandlerService: ErrorHandlerService,) {
    super(http, errorHandlerService);
  }

  private env = environment;

  getNextActivesWaves(planId: string): Observable<any[]> {
    return this.get<any[]>(`${this.env.publicApiSocioUrl}v1/socio/waves/nexts-waves/${planId}`);
  }

  getCheckinsRealizados(waveId: string, memberPlanId: string): Observable<any[]> {
    return this.get<any[]>(`${this.env.publicApiSocioUrl}v1/socio/checkin/realizados/${memberPlanId}/${waveId}`);
  }

  getLimitByMemberPlan(memberPlanId: string): Observable<any>{
    return this.get<any>(`${this.env.publicApiSocioUrl}v1/socio/checkin/limit-by-plan-id/${memberPlanId}`);
  }

  realizarCheckin(data: any): Observable<any> {
    return this.http.post<any>(`${this.env.publicApiSocioUrl}v1/socio/tickets/realizar-checkin`, data);
  }

  criarCheckin(data: any): Observable<any> {
    return this.http.post<any>(`${this.env.publicApiSocioUrl}v1/socio/checkin/create-checkin`, data);
  }

  public cancelByOrderId(orderId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.env.publicApiSocioUrl}v1/socio/tickets/order/cancel/` + orderId
    );
  }


  public cancelCheckin(checkinId: string, orderId: string): Observable<any> {
    return this.http.post<any>(
      `${this.env.publicApiSocioUrl}v1/socio/checkin/cancel-checkin/${checkinId}/${orderId}`, {}
    );
  }
}
