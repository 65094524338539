<div
  class="modal fade"
  id="{{ modalId() }}"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">
          {{ modalTitle() }}
        </h1>

        <button type="button" class="btn-close" (click)="closeModal()"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="substituteForm">
        <h1>teste</h1>
        <app-input-custom
          class="col-md-3"
          [id]="'CPFInputId'"
          [label]="'CPF'"
          [required]="true"
          [mask]="InputMaskTypesEnum.CPF"
          [placeholder]="'Insira seu CPF'"
          formControlName="cpfControl"
          (blur)="onChangeCpf()"
        >
        </app-input-custom>
        <app-input
          class="col-md-6"
          [id]="'fullNameInputId'"
          [type]="InputTypeEnum.NAME"
          [isUpperCase]="true"
          [required]="true"
          [label]="'Nome Completo'"
          [placeholder]="'Insira seu Nome'"
          formControlName="nameControl"
        >
        </app-input>
        <app-input
          class="col-md-6"
          [id]="'emailInputId'"
          [label]="'Email'"
          [required]="true"
          [type]="InputTypeEnum.EMAIL"
          [placeholder]="'Insira seu E-mail'"
          formControlName="emailControl"
        >
        </app-input>

        <div class="modal-footer">
          <app-button
            class="mx-2 my-1"
            id="confirmModalBtn"
            [buttonOptions]="cancelBtn"
            (buttonClick)="closeModal()"
          ></app-button>
          <app-button
            class="mx-2 my-1"
            id="confirmModalBtn"
            [buttonOptions]="confirmBtn"
            [disabled]="!substituteForm.valid"
            (buttonClick)="onConfirm()"
          ></app-button>
        </div>
      </form>
      </div>
    </div>
  </div>
</div>
