<section class="main-section d-flex py-2" [ngStyle]="styles" [ngClass]="{
     'align-items-start': sectionMain.elementPosition == 'TOPO',
    'align-items-center': sectionMain.elementPosition == 'CENTRO',
    'align-items-end': sectionMain.elementPosition === 'BASE',
  }">
  <div class="container d-flex flex-column" [ngClass]="{
    'text-center': true
  }">
    <h1 class="pb-3 d-flex flex-column">
      <span class="prefixo">{{componentData.prefixo}}
        <span *ngIf="sectionMain.hasCounter" class="highlight">
          <span class="specialCharacter">{{componentData.specialCharacter}}</span>
          {{componentData.highlight}}</span>
      </span>
      <span class="sufixo">{{componentData.sufixo}}</span>

    </h1>
    <p *ngIf="sectionMain.hasSubtitle" class="pb-5 px-4 subtitle">{{componentData.subtitle}}</p>
    <app-button *ngIf="sectionMain.hasJoinPlanButton" [buttonOptions]="buttonOptions" routerLink="/cadastro-socio">
    </app-button>
    
    <app-button class="mt-2 d-md-none" [buttonOptions]="loginButtonOptions" routerLink="/login">
    </app-button>
  </div>
</section>