import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CustomButtonDto, defaultCustomButtonDto } from './app-button-dto';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './app-button.component.html',
  styleUrls: ['./app-button.component.scss']
})
export class AppButtonComponent implements OnInit{

  @Input() buttonOptions: CustomButtonDto = defaultCustomButtonDto;
  @Input() disabled: boolean = false;
  @Input() type: 'button' | 'submit' | 'reset' = 'button';
  @Input() buttonSize: string;
  @Input() fontSize: number = 16;
  @Output() buttonClick = new EventEmitter<void>();
  @Input() style: string;
  @Input() isIcon: boolean = false;
  @Input() iconName: string;
  @Input() iconType: string;
  @Input() left: boolean;
  @Input() right: boolean;
  @Input() buttonType : string;
  @Input() onlyIcon: boolean = false;

  public styles: { [key: string]: string };
  buttonText: string;
  
  isMobile: boolean = false;

  onClick() {
    if (!this.disabled) {
      this.buttonClick.emit();
    }
  }

  constructor() {}

  ngOnInit() {
    this.checkIfMobile();
    if(this.buttonOptions === null || this.buttonOptions === undefined) return

    this.buttonOptions.primaryColor = this.buttonOptions?.primaryColor ?? defaultCustomButtonDto?.primaryColor;
    this.buttonOptions.secondaryColor = this.buttonOptions?.secondaryColor ?? defaultCustomButtonDto?.secondaryColor;
    this.setStyleProperties();
    this.setCSSVariables(this.buttonOptions?.primaryColor as string);

  }

  ngOnChanges(changes: SimpleChanges) {
    this.setStyleProperties();
    this.setCSSVariables(this.buttonOptions?.primaryColor as string);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkIfMobile();
  }

  private checkIfMobile() {
    this.isMobile = window.innerWidth <= 768; // Define o limite para considerar como mobile
  }

  private setStyleProperties() {
    let primaryColorDark, primaryColorDarker, btnDisabledBackground, secondaryColorDark, secondaryColorDarker;
    if (this.buttonOptions?.primaryColor) {
      [primaryColorDark, primaryColorDarker, btnDisabledBackground] = this.setCSSVariables(this.buttonOptions?.primaryColor);
    }
    if (this.buttonOptions?.secondaryColor) {
      [secondaryColorDark, secondaryColorDarker, btnDisabledBackground] = this.setCSSVariables(this.buttonOptions?.secondaryColor);
    }
    this.buttonText = this.buttonOptions?.buttonText as string;
    this.buttonSize = this.buttonOptions?.buttonSize || 'btn btn-md';
    this.buttonType = this.buttonType ? this.buttonType : 'btn-raised-primary';
    let primaryButton = JSON.parse(localStorage.getItem('buttonPrimary') as string);

    let secondaryButton = JSON.parse(localStorage.getItem('buttonSecondary') as string);
    this.styles = {
      '--primaryColor': this.buttonOptions?.primaryColor as string,
      '--secondaryColor': this.buttonOptions?.secondaryColor || '#191844',
      '--buttonTextColor': this.buttonOptions?.buttonTextColor as string,
      '--borderRadius': this.buttonOptions?.borderRadius as string,
      '--buttonBorderWidth': this.buttonOptions?.buttonBorderWidth as string,
      '--buttonBorderColor': this.buttonOptions?.buttonBorderColor as string,
      '--buttonTextHoverColor': this.buttonOptions?.btnTextHoverColor ? this.buttonOptions?.btnTextHoverColor as string : this.buttonOptions?.buttonTextColor as string,
      '--buttonBorderHoverColor': this.buttonOptions?.btnBorderHover ?  this.buttonOptions?.btnBorderHoverColor as string : this.buttonOptions?.buttonBorderColor as string,
      '--buttonHoverColor': this.buttonOptions?.btnHoverColor ? this.buttonOptions?.btnHoverColor as string : this.buttonOptions?.primaryColor as string,
      '--primaryColorDark': primaryColorDark,
      '--primaryColorDarker': primaryColorDarker,
      '--secondaryColorDark': secondaryColorDark,
      '--secondaryColorDarker': secondaryColorDarker,
      '--btnDisabledBackground': btnDisabledBackground,
      '--btnDisabledTextColor': this.buttonOptions?.btnDisabledTextColor  as string,
      '--fontSize': this.buttonOptions?.fontSize + 'px' as string,
      '--fontSizeMin': (this.fontSize - 2) + 'px' as string,
      '--primary-color-button': this.buttonOptions?.primaryColor ?? primaryButton?.color,
      '--primary-text-color-button': this.buttonOptions?.buttonTextColor ?? primaryButton?.textColor,
      '--primary-color-border-button': this.buttonOptions?.buttonBorderWidth ? this.buttonOptions?.buttonBorderColor : primaryButton?.hasBorder ?  primaryButton?.borderColor : 'transparent',
      '--secondary-color-button': secondaryButton?.color,
      '--secondary-text-color-button': secondaryButton?.textColor,
      '--secondary-color-border-button': secondaryButton?.hasBorder ? secondaryButton?.borderColor : 'transparent',
    };
  }

  setCSSVariables(color: string) {
    let rgb = color?.match(/\w\w/g);
    let primaryColorDark, primaryColorDarker, btnDisabledBackground, secondaryColorDark, secondaryColorDarker
    if (rgb) {
      let [r, g, b] = rgb.map((c) => parseInt(c, 16));
      let luminance = 0.299 * r + 0.587 * g + 0.114 * b;
      if (luminance < 128) {
        // Se a cor é escura, clareie-a
        primaryColorDark = this.lighten(color, 30);
        primaryColorDarker = this.lighten(color, 60);
        secondaryColorDark = this.lighten(color, 30);
        secondaryColorDarker = this.lighten(color, 60);
      } else {
        // Se a cor é clara, escureça-a
        primaryColorDark = this.darken(color, 30);
        primaryColorDarker = this.darken(color, 60);
        secondaryColorDark = this.darken(color, 30);
        secondaryColorDarker = this.darken(color, 60);
      }
      if (color === 'transparent') {
        primaryColorDark = 'inherit';
        primaryColorDarker = 'inherit';
      }
      btnDisabledBackground = this.createDisabledColor(color, 0.4);

    }
    return [primaryColorDark, primaryColorDarker, btnDisabledBackground, secondaryColorDark, secondaryColorDarker];
  }

  //funcao para criar a cor desabilitada
  createDisabledColor(color: string, opacity: number): string {
    const rgb = color.match(/\w\w/g);
    if (rgb) {
      const [r, g, b] = rgb.map((c) => parseInt(c, 16));
      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    } else {
      return color;
    }
  }

  darken(color: string, amount: number): any {
    let rgb = color.match(/\w\w/g);
    if (rgb) {
      let [r, g, b] = rgb.map((c) => Math.max(0, Math.min(255, parseInt(c, 16) - amount)));
      return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
    } else {
      return color;
    }
  }


  lighten(color: string, amount: number): any {
    let rgb = color.match(/\w\w/g);
    if (rgb) {
      let [r, g, b] = rgb.map((c) => Math.max(0, Math.min(255, parseInt(c, 16) + amount)));
      return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
    } else {
      return color;
    }
  }
}
