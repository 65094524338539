import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserService } from 'src/app/core/services/user.service';
import { Location } from '@angular/common';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { AppInputCustomComponent, InputMaskTypes } from 'src/app/shared/components/app-input-custom/app-input-custom.component';
import { AppInputComponent, InputType } from 'src/app/shared/components/app-input/app-input.component';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { AppModalComponent } from 'src/app/shared/components/app-modal/app-modal.component';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    AppButtonComponent,
    ReactiveFormsModule,
    AppInputCustomComponent,
    AppInputComponent,
    RouterLink,
    AppModalComponent // Adiciona o AppModalComponent aos imports
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit, OnDestroy {
  returnUrl: string;
  error: string;

  InputTypeEnum = InputType;
  InputMaskTypesEnum = InputMaskTypes;

  logoUrl: string = localStorage.getItem('imageLogo') || '';
  pageTitle = 'Faça seu login';

  loginFormGroup = new FormGroup({
    CPFControl: new FormControl<string>('', Validators.required),
    passwordControl: new FormControl<string>('', Validators.required),
  });

  buttonOptions = {
    buttonText: 'Voltar',
    buttonBorderWidth: '1px',
    buttonSize: 'btn btn-md px-3',
    borderRadius: '25px'
  };

  wannaBePartnerBtn = {
    buttonText: 'Quero ser sócio',
    borderRadius: '25px',
    buttonBorderWidth: '1px',
    buttonSize: 'btn btn-sm px-4 py-2 btn-primary'
  };

  loginBtn = {
    buttonText: 'Entrar',
    borderRadius: '25px',
    buttonBorderWidth: 'none',
    buttonSize: 'btn btn-sm px-5 py-2 btn-primary',
  };

  modalTitle = 'Bem-vindo!';
  modalId = 'welcomeModal';
  modalBodyMessage = 'Esta é uma mensagem de boas-vindas.';

  private _destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private location: Location,
    private alertService: AlertService,
    private notificationService: NotificationsService,
    private spinnerService: SpinnerService,
    private modalService: ModalService
  ) {
    const currentUserValue = this.userService.currentUserValue ? Object.keys(this.userService.currentUserValue) : null;
    if (currentUserValue?.length && !this.route.snapshot.queryParams['backoffice']) {
      this.router.navigate(['/dashboard']);
    } else {
      this.spinnerService.hide();
    }
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';

    const backoffice = this.route.snapshot.queryParams['backoffice'];

    if (backoffice === 'true') {
      const userId = this.route.snapshot.queryParams['login'];

      // Limpar os dados do localStorage relacionados ao usuário atual
      localStorage.removeItem('token');
      this.userService.removeCurrentUser();

      // Garantindo que o token foi removido e que o userID está na URL
      if (userId && !localStorage.getItem('token')) {
        this.loginMemberByBackoffice(userId);
      }
    }else{
      // Verificar se há uma mensagem de login no localStorage com atraso de 100ms
    setTimeout(() => {
      this.checkLoginMessage();
    }, 100);
    }

    
  }

  checkLoginMessage() {
    console.log('Verificando mensagem de login...',localStorage.getItem('hasLoginMessage'));
    const hasLoginMessage = localStorage.getItem('hasLoginMessage') === 'true';
    if (hasLoginMessage) {
      this.modalTitle = localStorage.getItem('loginMessageTitle') || 'Bem-vindo!';
      this.modalBodyMessage = localStorage.getItem('loginMessage') || 'Esta é uma mensagem de boas-vindas.';
      this.modalService.open(this.modalId);
    }
  }

  back() {
    this.location.back();
  }

  loginMemberByBackoffice(userId: string) {
    this.authService.loginMemberByBackoffice(userId)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => {
          this.router.navigate(['/dashboard']).then(success => {
            if (!success) {
              console.error('Falha no redirecionamento para o dashboard');
            }
          });
        },
        error: (error) => {
          this.alertService.showAlert(AlertType.DANGER, error.error.message || 'Erro ao fazer login automático.');
        }
      });
  }

  login() {
    const userCredentials = {
      username: this.loginFormGroup.get('CPFControl')?.value as string,
      password: this.loginFormGroup.get('passwordControl')?.value as string
    };

    this.userService.canLogin(userCredentials.username).subscribe({
      next: (isBlacklisted) => {
        if (!isBlacklisted) {
          this.alertService.showAlert(AlertType.DANGER, 'CPF está bloqueado para login.');
        } else {
          this.authService.login(userCredentials.username, userCredentials.password, this.returnUrl)
            .pipe(takeUntil(this._destroy$))
            .subscribe({
              next: () => {
                // this.notificationService.showSuccess('Login efetuado com sucesso!');
              },
              error: error => {
                if (error.error.code === 'ME037') {
                  this.router.navigate(['/login/reenviar-email-confirmacao'], { state: { cpf: userCredentials.username } });
                } else {
                  this.alertService.showAlert(AlertType.DANGER, error.error.message);
                }
              }
            });
        }
      },
      error: (error) => {
        this.alertService.showAlert(AlertType.DANGER, 'Erro ao verificar o CPF.');
      }
    });
  }

  onModalConfirm(event: any): void {
    console.log('Modal confirmado:', event);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}