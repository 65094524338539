import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/core/services/modal.service';
import { AppButtonComponent } from "../app-button/app-button.component";
import { AlertService, AlertType } from 'src/app/core/services/alert.service';

declare var bootstrap: any;

@Component({
  selector: 'app-confirm-modal',
  standalone: true,
  imports: [CommonModule, AppButtonComponent],
  templateUrl: './app-confirm-modal.component.html',
  styleUrl: './app-confirm-modal.component.scss'
})
export class AppConfirmModalComponent implements OnInit {

    public styles: { [key: string]: string };

    modalId = input.required<string>();
    modalTitle = input<string>('Atenção!');
    modalInstance: any;

    @Input() link:any;

    confirmBtn = {
      buttonText: 'Confirmar',
      borderRadius: '25px',
      buttonSize: 'btn btn-sm px-5 py-2 btn-secondary',
    };

    iconBtn = {
      borderRadius: '25px',
      buttonSize: 'btn btn-sm px-5 py-2 btn-secondary',
    };

    private modalSubscription: Subscription | undefined;

    @Output() onModalGuestConfirm = new EventEmitter();

    constructor(private modalService: ModalService, private alertService: AlertService) { }

    ngOnInit(): void {

      this.styles = {
        '--primaryColor': localStorage.getItem('primaryColor') as string
      };


      this.modalSubscription = this.modalService.modalState$.subscribe((state) => {
        if(state.id === 'modalConfirm'){
        const modalElement = document.getElementById(state.id);
        if (modalElement) {
          if (state.action === 'open') {
            this.modalInstance = new bootstrap.Modal(modalElement); // Instanciando o modal
            this.modalInstance.show();
            modalElement.addEventListener('hidden.bs.modal', () => {
            });
          } else if (state.action === 'close') {
            if (this.modalInstance) {
              this.modalInstance.hide();
            }
          }
        }
      }

      });

    }

    closeModal() {
      if (this.modalInstance) {
        this.modalInstance.hide();
      }
    }

    copyToClipboard(): void {
      navigator.clipboard.writeText(this?.link.toString()).then(() => {
        this.alertService.showAlert(AlertType.SUCCESS, 'Link copiado para a área de transferência com sucesso!');
      }).catch(err => {
        console.error('Falha ao copiar o texto para a área de transferência: ', err);
      });
    }



    onConfirm() {

      this.modalInstance.hide();
    }


}
