<div class="field-form-control" *ngIf="control">
  <label [for]="id" *ngIf="label" class="form-label" [style.color]="labelColor" [class.required]="isRequired">{{
    label
  }}</label>
  <input class="form-control"
    [required]="isRequired"
    [type]="'text'"
    [id]="id"
    [formControl]="control"
    [placeholder]="placeholder"
    [mask]="mask"
    (blur)="onBlur($event)"
    [dropSpecialCharacters]="dropSpecialCharacters"
  />

  <app-validation-errors *ngIf="control.touched"
    [customErrorMessages]="customErrorMessages"
    [errors]="control.errors"
  >
  </app-validation-errors>
</div>
