@if (content && showContent) {

<div class="container py-5 px-5">
  <div class="d-flex justify-content-end">
    <app-button
      id="back-desktop" [onlyIcon]="true"
      [isIcon]="true"
      [left]="true"
      [iconName]="'bi bi-arrow-left'"
      [buttonOptions]="backOptions"
      (buttonClick)="back()"
    >
    </app-button>
  </div>
  <div class="headerPage flex-space-between">
    <div>
      <h1 class="main-title" [style.color]="labelColor">
        {{ content.title }}
      </h1>
    </div>
    <div>
      <h1 class="data" [style.color]="labelColor">
        <span> Data: </span>
        {{ content.createdAt | date : "dd/mm/yyyy" }}
      </h1>
    </div>
  </div>
  <div class="flex-space-between">
    <h2 class="mb-3" [style.color]="labelColor">
      <span> Autor: </span>

      {{ content.author.name }}
    </h2>

    <h2 class="mb-3" [style.color]="labelColor">
      <span> Categoria: </span>
      {{ content.category.name }}
    </h2>
  </div>

  <div class="row mb-4 d-flex flex-column flex-md-row">
    <div class="col mb-4">
      <div class="card">
        <div
          class="content"
          id="content-container"
          [innerHTML]="content.content"
        ></div>
        <!--  <img
            class="banner"
            [src]="content.image.url"
            [alt]="content.title"
            [title]="content.title"
            *ngIf="content?.image?.url !== undefined "
            /> -->
        <div class="description" [innerHTML]="content.description"></div>
      </div>
    </div>
  </div>
</div>
}
