import { Component, OnDestroy, OnInit, Input, input, Output, EventEmitter } from '@angular/core';
import { QRCodeModule } from 'angularx-qrcode';
import { Subscription } from 'rxjs';
import { MemberService } from 'src/app/core/services/member.service';
import { ModalService } from 'src/app/core/services/modal.service';

declare var bootstrap: any;

@Component({
  selector: 'app-membershipcard-modal',
  standalone: true,
  imports: [QRCodeModule],
  templateUrl: './membershipcard-modal.component.html',
  styleUrls: ['./membershipcard-modal.component.scss']
})
export class MembershipCardModalComponent implements OnInit, OnDestroy {

  @Input() backdropType!: string;
  @Input() paymentModalData!: any;
  @Output() modalClosed = new EventEmitter<boolean>();

  memberShipCard = input<any>();
  modalId = input.required<string>();
  openDateTime: string;
  hexadecimalCard = localStorage.getItem('hexadecimalCard') === 'true' ? true : false;
  cardNumberHex: string;

  private modalSubscription: Subscription | undefined;

  constructor(
    private modalService: ModalService,
    private memberService: MemberService
  ) { }

  ngOnInit(): void {
    this.modalSubscription = this.modalService.modalState$.subscribe((state) => {
      if (state.id === this.modalId()) {
        const modalElement = document.getElementById(state.id);
        if (modalElement) {
          const modal = new bootstrap.Modal(modalElement, {
            backdrop: 'static',
            keyboard: false
          });

          if (state.action === 'open') {
            this.openDateTime = new Date().toLocaleString();

            const cardNumber = this.memberShipCard()?.cardNumber;
            if (this.hexadecimalCard && cardNumber) {
              this.memberService.getHexadecimalCardNumber(cardNumber).subscribe(res => {
                this.cardNumberHex = res?.cardNumber ? res.cardNumber : cardNumber;
              });
            } else {
              this.cardNumberHex = cardNumber;
            }
            modal.show();

          } else if (state.action === 'close') {
            modal.hide();
          }
        } else {
          console.error('Elemento do modal não encontrado!');
        }
      }
    });
  }

  onCancel() {
    // this.modalClosed.emit(true);
    this.modalService.close(this.modalId());
  }

  ngOnDestroy(): void {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
  }
}
