<div
  class="modal fade"
  id="{{ modalId() }}"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">
          {{ modalTitle() }}
        </h1>

        <button type="button" class="btn-close" (click)="closeModal()"></button>
      </div>

      <div class="modal-body">
        @if ( !this.openCheckin) {
        Checkin não habilitado no momento
        }
        @if (showSelectInput() && this.openCheckin) {
        <div class="d-flex justify-content-center">
          @if(validGuestLimit()){
            <div class="modal-guest d-flex justify-content-center mb-2">
              <app-button
                  class="mx-2 my-1"
                  id="guestModalBtn"
                  [disabled]="this.openCheckin ? false : true"
                  [buttonOptions]="guestBtn"
                  (buttonClick)="openGuestModal()"
              ></app-button>
            </div>
          }
          @if(this.openCheckin && validAssociateCheckin() &&  this.openSubstitute){
            <div class="modal-guest d-flex justify-content-center mb-2">
              <app-button
                  class="mx-2 my-1"
                  id="substituteModalBtn"
                  [disabled]="this.openCheckin ? false : true"
                  [buttonOptions]="substituteBtn"
                  (buttonClick)="openSubstituteModal()"
              ></app-button>
            </div>
          }
        </div>
        <app-select-input
          class="col-md-4"
          [id]="'selectInputId'"
          [label]="'Selecionar Usuário'"
          [options]="selectInputOptions"
          [formControl]="selectControl()"
          (ngModelChange)="alterarTickets()"
        ></app-select-input>
        } @if (showSelectInput()  && this.openCheckin) {
        <app-select-input
          class="col-md-4"
          [id]="'selectTicketInputId'"
          [label]="'Selecionar Ingresso'"
          [options]="ticketsOptions"
          [formControl]="ticketControl()"
        ></app-select-input>
        }

        <div class="modal-footer">
          <app-button
            class="mx-2 my-1"
            id="cancelModalBtn"
            [buttonOptions]="cancelBtn"
            data-bs-dismiss="modal"
          ></app-button>

          <app-button
            class="mx-2 my-1"
            id="confirmModalBtn"
            [disabled]="this.openCheckin && this.validForm() ? false : true"
            [buttonOptions]="confirmBtn"
            (buttonClick)="onConfirm()"
          ></app-button>
        </div>

        <hr />

        @if (checkinsHistory.length > 0) {
        <div class="payment-history px-3">
          <div class="payment-history-title text-left py-3">
            Lista de Checkins Realizados
          </div>
          <div class="history pt-4">
            <div class="history-types">
              <div class="table">
                <table class="table table-striped">
                  <thead>
                    <tr class="text-center">
                      <th scope="col">Nome</th>
                      <th scope="col">Ingresso</th>
                      <th scope="col">Situação</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  @for (i of checkinsHistory; track $index) {
                  <tbody>
                    <tr class="text-center">
                      <td>
                        {{
                          i?.isDependent
                            ? i.memberDependentName + '(Dependente)'
                            : i.isGuest ? i.memberGuestName + '(Convidado)' :
                            i.isSubstitute ? i.memberSubstituteName + '(Substituto)' :
                            i.memberPlanName + '(Sócio)'
                        }}
                      </td>
                      <td>{{ getTicketById(i) }}</td>
                      <td>{{ i?.checkinStatus }}</td>
                      <td>
                        <div class="d-flex align-middle">
                          <app-button
                            [disabled]="i?.checkinStatus == 'Cancelado' ? true : false"
                            id="view-checkin"
                            [buttonOptions]="visualizarOptions"
                            (buttonClick)="visualizarCheckin(i.ticketLink)"
                          >
                          </app-button>
                          <app-button
                          id="cancel-checkin"
                          [disabled]="i?.checkinStatus == 'Cancelado' ? true : false || !this.isCanceled ? true : false || i?.ticketValue > 0 ? true : false"
                          [buttonOptions]="cancelarOptions"
                          (buttonClick)="cancelarCheckin(i)"
                        >
                        </app-button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  }
                </table>
              </div>
            </div>
          </div>
        </div>

        }

      </div>

    </div>
  </div>
</div>

<app-guest-modal
[modalId]="'modalGuest'"
[modalTitle]="'Adicionar Convidado'"
(onModalGuestConfirm)="onModalGuestConfirm($event)"
></app-guest-modal>

<app-substitute-modal
[modalId]="'modalSubstitute'"
[modalTitle]="'Adicionar Substituto'"
(onModalSubstituteConfirm)="onModalSubstituteConfirm($event)">
</app-substitute-modal>
