import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { LayoutConfigService } from 'src/app/shared/services/layout-config.service';
import { FaqService } from './faq.service';
import { Location } from '@angular/common';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-faq',
  standalone: true,
  imports: [CommonModule, AppButtonComponent],
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss'
})
export class FaqComponent implements OnInit {

  public btnBackOptions: any;
  questions: any;
  constructor(private layoutConfigService: LayoutConfigService,
    private faqService: FaqService,
    private location: Location,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    const layoutVersion = this.layoutConfigService.getLayoutVersion();
    this.btnBackOptions = {
      buttonText: 'Voltar',
      buttonSize: 'btn btn-md px-3 btn-secondary',
      borderRadius: layoutVersion === 1 ? '25px' : '0px'
    };
    this.getAllQuestions();
  }

  back() {
    this.location.back();
  }

  getAllQuestions(): void {
    this.faqService.getAllQuestions().subscribe((response: any) => {
      this.questions = response.content;
    });
  }
}
