import { Component, EventEmitter, input, Output } from '@angular/core';
import { AppInputComponent, InputType } from '../app-input/app-input.component';
import { AppInputCustomComponent, InputMaskTypes } from '../app-input-custom/app-input-custom.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AppButtonComponent } from '../app-button/app-button.component';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/core/services/modal.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { SubstituteService } from 'src/app/core/services/substitute.service';

declare var bootstrap: any;


@Component({
  selector: 'app-substitute-modal',
  standalone: true,
  imports: [AppInputComponent, AppInputCustomComponent, ReactiveFormsModule, AppButtonComponent],
  templateUrl: './app-substitute-modal.component.html',
  styleUrl: './app-substitute-modal.component.scss'
})
export class AppSubstituteModalComponent {

   modalId = input.required<string>();
    modalTitle = input<string>('Adicionar Substituto');
    modalInstance: any;
    modalConfirmLabel = input<string>('');
    modalCancelLabel = input<string>('');

    InputTypeEnum = InputType;
    InputMaskTypesEnum = InputMaskTypes;

    substituteForm = new FormGroup({
      idControl: new FormControl<string>(''),
      nameControl: new FormControl<string>('', [Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ]{3,}( {1,2}[a-zA-ZÀ-ÿ]{2,}){1,}$')]),
      cpfControl: new FormControl<string>('', Validators.required),
      emailControl: new FormControl<string>('', [Validators.required, Validators.email])
    });

    confirmBtn = {
      buttonText: 'Confirmar Dados',
      borderRadius: '25px',
      buttonSize: 'btn btn-sm px-5 py-2 btn-secondary',
    };

    cancelBtn = {
      buttonText: 'Cancelar',
      borderRadius: '25px',
      buttonSize: 'btn btn-sm px-5 py-2 btn-secondary',
    };

    private modalSubscription: Subscription | undefined;

    @Output() onModalSubstituteConfirm = new EventEmitter();

    constructor(private modalService: ModalService,
      private substituteService: SubstituteService,
      private alertService: AlertService) { }

    ngOnInit(): void {
      this.modalSubscription = this.modalService.modalState$.subscribe((state) => {
        if(state.id === 'modalSubstitute'){
        const modalElement = document.getElementById(state.id);
        if (modalElement) {
          if (state.action === 'open') {
            this.modalInstance = new bootstrap.Modal(modalElement); // Instanciando o modal
            this.modalInstance.show();
            modalElement.addEventListener('hidden.bs.modal', () => {
              this.substituteForm.reset();
            });
          } else if (state.action === 'close') {
            if (this.modalInstance) {
              this.modalInstance.hide();
            }
          }
        }
      }

      });

      if (this.modalId() === 'modalSubstitute') {
      }

    }

    closeModal() {
      this.substituteForm.reset();
      if (this.modalInstance) {
        this.modalInstance.hide();
      }
    }


    get controls(): any{
      return this.substituteForm.controls;
    }

    onConfirm() {
      const data = this.substituteForm.getRawValue();
      if(this.substituteForm.invalid){
        return
      }
      this.onModalSubstituteConfirm.emit(data);
      this.modalInstance.hide();
      this.substituteForm.reset();
    }

    onChangeCpf(){
      let cpf = this.substituteForm.get('cpfControl')?.value;
      if(cpf && cpf.length == 11){
        this.substituteService.findSubstituteByCpf(cpf).subscribe({
          next : (res:any) => {
            this.substituteForm.get('nameControl')?.setValue(res?.name)
            this.substituteForm.get('emailControl')?.setValue(res?.email)
            this.substituteForm.get('idControl')?.setValue(res?.id)
          }
        })
      }

    }

}
