import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { FileUploadComponent } from 'src/app/shared/components/app-file-upload/app-file-upload.component';
import { Location } from '@angular/common';
import { MemberService } from 'src/app/core/services/member.service';
import { UserModel } from 'src/app/shared/models/userModel';
import { UserService } from 'src/app/core/services/user.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';


@Component({
  selector: 'app-documentos',
  standalone: true,
  imports: [CommonModule, AppButtonComponent, FileUploadComponent],
  templateUrl: './documentos.component.html',
  styleUrl: './documentos.component.scss'
})
export class DocumentosComponent {
  labelColor = 'white';
  currentUser: UserModel;
  accessToken: string;
  listDocuments: any[] = [];
  dependentList: any[] = [];
  usersList: any[] = [];
  lastDocument: any = null;

  constructor(
    private location: Location,
    private memberService: MemberService,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private alertService: AlertService
  ) {
    this.currentUser = this.userService.currentUserValue ?? {} as UserModel;
    this.accessToken = this.localStorageService.getItem('token');
  }

  backOptions = {
    buttonText: 'Voltar',
    buttonSize: 'btn btn-md px-3 btn-secondary',
    borderRadius: '25px'
  };

  ngOnInit(): void {
    this.loadDocuments();
  }

  loadDocuments() {
    if (this.currentUser?.memberPlans) {
      const memberId = this.currentUser.id;
      this.memberService.getDocumentsMember(memberId, this.accessToken).subscribe((document) => {
        this.listDocuments = document;
      });

      this.memberService.getMemberDependentsWithoutDocuments(memberId).subscribe((res) => {
        this.dependentList = [...res];

        this.memberService.getLastMemberDocuments(memberId).subscribe((document) => {
          this.lastDocument = document;

          if (res?.length > 0) {
            if (!document || document.status === 'REJECTED') {
              this.usersList = [
                { id: this.currentUser?.id, name: this.currentUser?.name },
                ...res
              ];
            } else {
              this.usersList = [...res]
            }
          }
        });
      });
    }
  }

  showAlert(type: AlertType, message: string): void {
    this.alertService.showAlert(type, message);
  }

  getDocumentsMember(fileSaved: boolean) {
    if (fileSaved) {
      this.showAlert(AlertType.SUCCESS, 'Documento enviado com sucesso!');
      this.loadDocuments();
    }
  }

  canUpload(): boolean {
    return this.listDocuments.length === 0 || this.canUploadMemberDocument() || this.dependentList.length > 0
  }

  canUploadMemberDocument(): boolean {
    return !this.lastDocument || this.lastDocument.status === 'REJECTED';
  }

  getUserName(item: any) {
    return item.memberDependent ? item.memberDependent.name : this.currentUser.name;
  }

  showUserColumn() {
    return this.listDocuments.some(doc => doc.memberDependent && doc.memberDependent.id) || this.dependentList.length > 0;
  }

  back() {
    this.location.back();
  }
}
