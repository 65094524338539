import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, input, model } from '@angular/core';
import { Subscription, filter } from 'rxjs';
import { ModalService } from 'src/app/core/services/modal.service';
import { AppButtonComponent } from '../app-button/app-button.component';
import { AppSelectInputComponent, SelectModel } from '../app-select-input/app-select-input.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CNPJPipe } from '../../pipe/cnpj.pipe';
import { CustomButtonDto } from '../app-button/app-button-dto';
import { WaveService } from 'src/app/core/services/wave.service';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';
import { GuessModalComponent } from "../guess-modal/guess-modal.component";
import { AppGuestModalComponent } from "../app-guest-modal/app-guest-modal.component";
import { AppSubstituteModalComponent } from "../app-substitute-modal/app-substitute-modal.component";

declare var bootstrap: any;

@Component({
  selector: 'app-form-modal',
  standalone: true,
  imports: [AppButtonComponent, AppSelectInputComponent, ReactiveFormsModule, AppGuestModalComponent, AppSubstituteModalComponent],
  templateUrl: './app-form-modal.component.html',
  styleUrl: './app-form-modal.component.scss'
})
export class AppFormModalComponent implements OnInit, OnDestroy {
  modalId = input.required<string>();
  modalTitle = input<string>('Modal de confirmação');
  modalBodyMessage = input<string>("");
  modalConfirmLabel = input<string>('');
  modalCancelLabel = input<string>('');
  showSelectInput = input<boolean>(true);
  showCloseBtn = input<boolean>(true);
  isCanceled: boolean;
  openCheckin: boolean;
  openSubstitute: boolean;

  guestData: any;
  substituteData: any;

  selectControl = input<FormControl>(new FormControl());
  ticketControl = input<FormControl>(new FormControl());
  isDependent = false;
  isGuest = false;
  isSubstitute = false;

  socioAfterLimit = false;
  dependentAfterLimit = false;
  guestAfterLimit = false;

  @Input() selectInputLabel: string;
  @Input() checkinSelected: any;
  @Input() selectInputOptions: SelectModel[];
  @Input() ticketData: any;
  @Input() checkinsHistory: any;
  @Input() limitCheckin: any;


  ticketsOptions: any[] = [];
  modalInstance: any; // Declaração da variável modalInstance para armazenar a instância do modal

  @Output() onModalConfirm = new EventEmitter();

  confirmAction = model<boolean | FormControl>(false);

  private modalSubscription: Subscription | undefined;

  cancelBtn = {
    buttonText: 'Cancelar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-5 py-2 btn-secondary',
  };

  confirmBtn = {
    buttonText: 'Confirmar Check-in',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-5 py-2 btn-primary',
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000'
  };

  guestBtn = {
    buttonText: 'Adicionar Convidado',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-5 py-2 btn-secondary',
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000'
  }

  substituteBtn = {
    buttonText: 'Adicionar Substituto',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-5 py-2 btn-secondary',
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000'
  }

  visualizarOptions = {
    buttonText: 'Visualizar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-2 btn-primary',
  } as CustomButtonDto;

  cancelarOptions = {
    buttonText: 'Cancelar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-2 btn-secondary',
  } as CustomButtonDto;

  constructor(private modalService: ModalService,
    private waveService: WaveService,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.modalSubscription = this.modalService.modalState$.subscribe((state) => {
      if (state.id === 'modalCheckinTicket') {
        const modalElement = document.getElementById('modalCheckinTicket');
        if (modalElement) {

          if (state.action === 'open') {

            this.modalInstance = new bootstrap.Modal(modalElement); // Instanciando o modal
            this.modalInstance.show();
            modalElement.addEventListener('hidden.bs.modal', () => {
              this.resetModal();
            });
            setTimeout(() => {
              this.alterarTickets();
              this.setStatus();
              this.validHasSubstitute();
              this.validLimitsCheckin();
              this.selectControl().setValue(this.selectInputOptions[0].code);
            }, 200);
          } else if (state.action === 'close') {
            if (this.modalInstance) {
              this.modalInstance.hide();
            }
          }
        }
      }

    });

    if (this.modalId() === 'modalCheckinTicket') {
      // Logica adicional se necessário
    }



    this.setBtnLabel();
    this.ticketControl().disable();
  }


  openGuestModal(): void {
    this.modalService.open('modalGuest');
  }

  openSubstituteModal(): void {
    this.modalService.open('modalSubstitute');
  }

  validAssociateCheckin(): boolean {
    let hasAssociateCheckin = this.checkinsHistory.find((c: any) => c.checkinStatus.includes('Realizado') && c.isDependent == false && c.isGuest == false);
    if (hasAssociateCheckin) {
      return false;
    } else {
      return true;
    }
  }

  validLimitsCheckin(): void {
    const waveSelect = this.ticketData.find((i: any) => i.id === this.checkinSelected);
    if (this.limitCheckin.limitChechin !== null && this.limitCheckin.limitChechin > 0 && this.limitCheckin.limitChechinUsed >= this.limitCheckin.limitChechin) {
      if (waveSelect?.waveTicketsAfterLimitSocio.length > 0 && waveSelect?.waveTicketsAfterLimitSocio !== null) {
        this.socioAfterLimit = true;
      } else {
        this.alertService.showAlert(AlertType.INFO, "Você atingiu o limite de checkins para sócios nesse plano");
        let associateIndex = this.selectInputOptions.findIndex((a: any) =>
          a.name.includes('Sócio')
        );
        if (associateIndex !== -1) {
          this.selectInputOptions.splice(associateIndex, 1);
        }
      }

    }
    if (this.limitCheckin.limitDependentCheckin > 0 && this.limitCheckin.limitDependentCheckin !== null && this.limitCheckin.limitDependentCheckin <= this.limitCheckin.limitDependentCheckinUsed) {
      if (waveSelect?.waveTicketsAfterLimitDependent.length > 0 && waveSelect?.waveTicketsAfterLimitDependent != null) {
        this.dependentAfterLimit = true;
      } else {
        this.alertService.showAlert(AlertType.INFO, "Você atingiu o limite de checkins para dependentes nesse plano");
        this.selectInputOptions = this.selectInputOptions.filter(option => {
          return !option.name.includes('Dependente');
        });
      }
    }
    if (this.limitCheckin.limitGuestCheckin > 0 && this.limitCheckin.limitGuestCheckin !== null && this.limitCheckin.limitGuestCheckin <= this.limitCheckin.limitGuestCheckinUsed) {
      if (waveSelect?.waveTicketsAfterLimitGuest.length > 0 && waveSelect?.waveTicketsAfterLimitGuest != null) {
        this.guestAfterLimit = true;
      } else {
        this.alertService.showAlert(AlertType.INFO, "Você atingiu o limite de checkins para convidados nesse plano");
        this.selectInputOptions = this.selectInputOptions.filter(option => {
          return !option.name.includes('Convidado');
        });
      }
    }
  }

  validHasSubstitute(): void {
    let hasSubstituteCheckin = this.checkinsHistory.find((c: any) =>
      c.checkinStatus.includes('Realizado') && c.isSubstitute === true
    );

    if (hasSubstituteCheckin) {
      let associateIndex = this.selectInputOptions.findIndex((a: any) =>
        a.name.includes('Sócio')
      );

      if (associateIndex !== -1) {
        this.selectInputOptions.splice(associateIndex, 1);
      }
    }
  }


  closeModal() {
    this.resetModal();
    if (this.modalInstance) {
      try {
        this.modalInstance.hide();
      }
      catch (error) {
        console.log(error, "chegou esse erro")

      }
    }
  }

  resetModal() {
    this.selectControl().reset();
    this.ticketControl().reset();
    this.ticketControl().disable();
    this.ticketsOptions = [];
    this.isDependent = false;
  }

  setBtnLabel(): void {
    if (this.modalCancelLabel() !== '')
      this.cancelBtn.buttonText = this.modalCancelLabel();
    if (this.modalConfirmLabel() !== '')
      this.confirmBtn.buttonText = this.modalConfirmLabel();
  }

  ngOnDestroy(): void {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
  }

  cancelarCheckin(checkin: any) {

    this.waveService.cancelCheckin(checkin.id, checkin.orderId).subscribe({
      next: (res: any) => {
      this.closeModal();
      this.alertService.showAlert(AlertType.SUCCESS, 'Checkin cancelado com sucesso.');
      window.location.reload();
      }, error: (error) => {
        console.error('Error loading data:', error);
      }
    })

  }

  validGuestLimit(): boolean {

    const limit = this.ticketData.find((i: any) => i.id === this.checkinSelected).guestLimit;
    let count = this.checkinsHistory.filter((c: any) => c.checkinStatus.includes('Realizado') && c.isGuest == true);
    if (count.length >= limit || !this.limitCheckin.permitGuestTicket) {
      return false;
    }
    if (this.limitCheckin.permitGuestTicket && this.limitCheckin.limitGuestCheckin > 0 && this.limitCheckin.limitGuestCheckin !== null && this.limitCheckin.limitGuestCheckin <= this.limitCheckin.limitGuestCheckinUsed) {
      return false;
    }
    else {
      return true;
    }
  }

  visualizarCheckin(link: any) {
    window.open(link, '_blank');
  }

  getTicketById(checkin: any) {
    const waveSelect = this.ticketData.find((i: any) => i.id === this.checkinSelected);
    if (checkin.memberDependentName) {
      return waveSelect.waveTicketsDependent.find((i: any) => i.id === checkin.ticket)?.ticketName
    } else if (checkin.memberGuestName){
      return waveSelect.waveTicketsGuest.find((i: any) => i.id === checkin.ticket)?.ticketName
    }
    else {
      return waveSelect.waveTicketsSocio.find((i: any) => i.id === checkin.ticket)?.ticketName
    }
  }

  setStatus() {
    this.isCanceled = this.ticketData.find((i: any) => i.id === this.checkinSelected).isCancel;
    this.openCheckin = this.ticketData.find((i: any) => i.id === this.checkinSelected).openCheckin;
    this.openSubstitute = this.ticketData.find((i: any) => i.id === this.checkinSelected).openSubstitute;
  }

  alterarTickets() {
    const waveSelect = this.ticketData.find((i: any) => i.id === this.checkinSelected);
    let clientSelect = this.selectInputOptions.find(i => i.code === this.selectControl()?.value);
    if (clientSelect) {
      this.ticketControl().enable();
    }
    if (clientSelect?.name.includes('Sócio') && waveSelect?.waveTicketsSocio.length > 0) {
      this.isDependent = false;
      this.isGuest = false;
      this.ticketsOptions = [];
      if (this.socioAfterLimit) {
        waveSelect.waveTicketsAfterLimitSocio.forEach((t: any) => {
          let ti: SelectModel = {
            code: t.ticketId,
            name: t.ticketName
          }
          this.ticketsOptions.push(ti);
        });
      } else {
        waveSelect.waveTicketsSocio.forEach((t: any) => {
          let ti: SelectModel = {
            code: t.ticketId,
            name: t.ticketName
          }
          this.ticketsOptions.push(ti);
        });
      }
    } else if (clientSelect?.name.includes('Substituto') && waveSelect?.waveTicketsSocio.length > 0) {
      this.isDependent = false;
      this.isGuest = false;
      this.isSubstitute = true;
      this.ticketsOptions = [];
      if (this.socioAfterLimit) {
        waveSelect.waveTicketsAfterLimitSocio.forEach((t: any) => {
          let ti: SelectModel = {
            code: t.ticketId,
            name: t.ticketName
          }
          this.ticketsOptions.push(ti);
        });
      } else {
        waveSelect.waveTicketsSocio.forEach((t: any) => {
          let ti: SelectModel = {
            code: t.ticketId,
            name: t.ticketName
          }
          this.ticketsOptions.push(ti);
        });
      }
    } else if (clientSelect?.name.includes('Dependente') && waveSelect?.waveTicketsDependent.length > 0) {
      this.isDependent = true;
      this.isGuest = false;
      this.isSubstitute = false;
      this.ticketsOptions = [];
      if(this.dependentAfterLimit){
        waveSelect.waveTicketsAfterLimitDependent.forEach((t: any) => {
          let td: SelectModel = {
            code: t.ticketId,
            name: t.ticketName
          }
          this.ticketsOptions.push(td);
        });
      } else {
        waveSelect.waveTicketsDependent.forEach((t: any) => {
          let td: SelectModel = {
            code: t.ticketId,
            name: t.ticketName
          }
          this.ticketsOptions.push(td);
        });
      }
    } else if (clientSelect?.name.includes('Convidado') && waveSelect?.waveTicketsGuest.length > 0) {
      this.isDependent = false;
      this.isGuest = true;
      this.isSubstitute = false;
      this.ticketsOptions = [];
      if(this.guestAfterLimit){
        waveSelect.waveTicketsAfterLimitGuest.forEach((t: any) => {
          let td: SelectModel = {
            code: t.ticketId,
            name: t.ticketName
          }
          this.ticketsOptions.push(td);
        });
      } else {
        waveSelect.waveTicketsGuest.forEach((t: any) => {
          let td: SelectModel = {
            code: t.ticketId,
            name: t.ticketName
          }
          this.ticketsOptions.push(td);
        });
      }


    }


    if (clientSelect) {
      this.ticketControl().setValue(this.ticketsOptions[0]?.code)
    }

  }

  onModalGuestConfirm(event: any) {
    let option: SelectModel = {
      code: '1',
      name: `${event.nameControl} (Convidado)`
    }
    this.selectInputOptions.push(option);
    this.alertService.showAlert(AlertType.SUCCESS, 'Dados do convidado adicionados! Para finalizar confirme o check-in.');
    this.selectControl().setValue(option.code);
    this.guestData = event;
  }

  onModalSubstituteConfirm(event: any) {
    let option: SelectModel = {
      code: '1',
      name: `${event.nameControl} (Substituto)`
    }
    this.selectInputOptions.push(option);
    this.alertService.showAlert(AlertType.SUCCESS, 'Dados do substituto adicionados! Para finalizar confirme o check-in.');
    this.selectControl().setValue(option.code);
    this.substituteData = event;
  }

  validForm(): boolean {
    if (this.selectControl().value == null || this.ticketControl().value == null) {
      return false;
    } else {
      return true;
    }
  }

  onConfirm() {
    if (this.selectControl().value == null || this.ticketControl().value == null) {
      console.log("entrou aqui")
      // window.alert("Preencha as informações antes de confirmar.")
      this.alertService.showAlert(AlertType.WARNING, 'Preencha as informações antes de confirmar!');
    } else {
      const dataConfirm: any = {
        clientSelected: this.selectControl().value,
        ticketSelected: this.ticketControl().value,
        isDependent: this.isDependent,
        isGuest: this.isGuest,
        guestData: this.guestData,
        isSubstitute: this?.isSubstitute,
        substituteData: this?.substituteData
      }
      console.log(dataConfirm, "manda isso")
      this.onModalConfirm.emit(dataConfirm);
      this.modalInstance.hide();
    }
  }
}
