<div
  class="modal fade"
  [ngStyle]="styles"
  id="{{ modalId() }}"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" >
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">
          {{ modalTitle() }}
        </h1>
        <button type="button" class="btn-close" (click)="closeModal()"></button>
      </div>
      <div class="modal-body" >
        <span>
          Convidado(a) sem foto cadastrada!
          <br><br>
          Compartilhe o link a seguir com o convidado para que possa cadastrar a foto e ter o ingresso com reconhecimento facial:
        </span>
        <br>
        <span class="d-flex align-items-center" >
          <a class="text-break" id="link" (click)="copyToClipboard()">{{ link }}</a>
        </span>
        <div class="modal-footer mt-1">
          <app-button
            class="mx-2 my-1"
            id="confirmModalBtn"
            [buttonOptions]="confirmBtn"
            (buttonClick)="onConfirm()"
          ></app-button>
        </div>
      </div>
    </div>
  </div>
</div>
