import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from './error-handler.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EveryoneCardService extends BaseService{

  private env = environment;

  constructor(
      http: HttpClient,
      errorHandlerService: ErrorHandlerService) {
        super(http, errorHandlerService);
      }


  sendAdesaoData(data: any): Observable<any> {
      return this.http.post<any>(`${this.env.publicApiSocioUrl}v1/socio/everyone-card/solicitar-adesao`, data);
  }

}
