import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppInputComponent, InputType } from 'src/app/shared/components/app-input/app-input.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AppSelectInputComponent, SelectModel } from 'src/app/shared/components/app-select-input/app-select-input.component';
import { AppInputCustomComponent, InputMaskTypes } from 'src/app/shared/components/app-input-custom/app-input-custom.component';
import { AppCheckboxComponent } from 'src/app/shared/components/app-checkbox/app-checkbox.component';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { UserService } from 'src/app/core/services/user.service';
import { CommonService } from 'src/app/core/services/common.service';
import { MemberService } from 'src/app/core/services/member.service';
import { UserModel } from 'src/app/shared/models/userModel';
import { CepService } from 'src/app/core/services/cep.service';

@Component({
  selector: 'app-dados-de-socio',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, AppInputComponent, AppInputCustomComponent, AppSelectInputComponent, AppCheckboxComponent, AppButtonComponent],
  templateUrl: './dados-de-socio.component.html',
  styleUrls: ['./dados-de-socio.component.scss']
})
export class DadosDeSocioComponent implements OnInit {
  InputTypeEnum = InputType;
  InputMaskTypesEnum = InputMaskTypes;

  // TODO: Rever estrutura de color
  labelColor = 'white';

  buttonOptions = {
    buttonText: 'Voltar',
    buttonSize: 'btn btn-md px-3 btn-secondary',
    borderRadius: '25px'
  };

  submitBtn = {
    buttonText: 'Salvar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-4 btn-primary',
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000'
  };

  partnerDataformGroup = new FormGroup({
    name: new FormControl<string>('', Validators.required),
    cpfControl: new FormControl<string>({ value: '', disabled: true }, Validators.required),
    birthDate: new FormControl<string>('', Validators.required),
    genderControl: new FormControl<string>('', Validators.required),
    emailControl: new FormControl<string>('', [Validators.required, Validators.email]),
    phoneControl: new FormControl<string>('', Validators.required),
    CEPControl: new FormControl<string>('', Validators.required),
    addressControl: new FormControl<string>('', Validators.required),
    numberControl: new FormControl<number>(0, Validators.required),
    complementControl: new FormControl<string>(''),
    neighborhoodControl: new FormControl<string>('', Validators.required),
    stateControl: new FormControl<string>('', Validators.required),
    cityControl: new FormControl<string>('', Validators.required),
    rankingConfirmarionControl: new FormControl<boolean>(false),
  });

  protected genderOptions = [
    { code: 'M', name: 'Masculino' },
    { code: 'F', name: 'Feminino' },
    { code: 'NI', name: 'Não informado' }
  ] as SelectModel[];

  protected stateOptions = [] as SelectModel[];


  protected cityOptions = [] as SelectModel[];
  user: UserModel;

  constructor(
    private router: Router,
    private location: Location,
    private userService: UserService,
    private commonService: CommonService,
    private memberService: MemberService,
    private cepService: CepService
  ) {
    this.user = this.userService.currentUserValue as UserModel;
  }

  partner: any = null;
  addressId: any = null;

  ngOnInit(): void {
    this.commonService.getStatePublic().subscribe(states => {
      this.stateOptions = states.map((obj: any) => ({ code: obj.id, name: obj.name }));
      this.loadMemberData();
    });

    this.memberService.getMemberDashboardData(this.user.id).subscribe(res => {
      this.partner = res;
    })
    this.initializeForm(this.partnerDataformGroup);
  }

  getAddressByCEP(): void {
    const cep = this.partnerDataformGroup.get('CEPControl')?.value as string;
    if (cep.length === 8) {
      this.cepService.searchCep(Number(cep)).subscribe((response) => {
        this.partnerDataformGroup.get('CEPControl')?.setValue(response.cep);
        this.partnerDataformGroup.get('addressControl')?.setValue(response.logradouro);
        this.partnerDataformGroup.get('neighborhoodControl')?.setValue(response.bairro);
        this.partnerDataformGroup.get('stateControl')?.setValue(
          this.stateOptions.find((state) => state.name === response.estado)?.code as string
        );


        this.commonService.getCity(this.partnerDataformGroup.get('stateControl')?.value).subscribe((cityResponse) => {
          this.cityOptions = cityResponse.map((city: any) => ({
            code: city.id,
            name: city.name,
          }));

          const city = this.cityOptions.find((c:any) => c.code === Number(response.ibge))?.code ;
          if (city) {
            this.partnerDataformGroup.get('cityControl')?.setValue(city);
          }
        });
      });
    }
  }


  private initializeForm(formGroup: FormGroup): void {
    if (this.partner) {
      let address = this?.partner?.addresses[0];

      formGroup.get('name')?.setValue(this.partner.name);
      formGroup.get('cpfControl')?.setValue(this.partner.cpf);
      formGroup.get('birthDate')?.setValue(this.partner.birthDate);
      formGroup.get('genderControl')?.setValue(this.partner.gender);
      formGroup.get('emailControl')?.setValue(this.partner?.emails[0]?.email);
      formGroup.get('phoneControl')?.setValue(this.partner?.phones[0]?.number);
      formGroup.get('CEPControl')?.setValue(address?.zipCode);
      formGroup.get('addressControl')?.setValue(address?.area);
      formGroup.get('numberControl')?.setValue(address?.number);
      formGroup.get('complementControl')?.setValue(address?.complement); // Adiciona o complemento
      formGroup.get('neighborhoodControl')?.setValue(address?.neighborhood);
      formGroup.get('stateControl')?.setValue(address?.stateCode);
      formGroup.get('cityControl')?.setValue(address?.cityCode);
      formGroup.get('rankingConfirmarionControl')?.setValue(this.partner.participatesInRanking);

      if(formGroup.get('cityControl')?.value == null){
        this.getCities();
      }
    }
  }

  private loadStates(): void {
    this.commonService.getStatePublic().subscribe(states => {
      this.stateOptions = states.map((obj: any) => ({ code: obj.id, name: obj.name }));
    });
  }

  private loadMemberData(): void {
    this.memberService.getMemberDashboardData(this.user.id).subscribe(res => {
      this.partner = res;
      this.populateForm();
    });
  }

  private populateForm(): void {
    if (this.partner) {
      const address = this.partner.addresses[0];
      const email = this.partner.emails.find((e: any) => e.isPrincipal);
      const phone = this.partner.phones.find((p: any) => p.isPrincipal);

      this.partnerDataformGroup.patchValue({
        name: this.partner.name,
        cpfControl: this.partner.cpf,
        birthDate: this.partner.birthDate.split('-').reverse().join('/'),
        genderControl: this.partner.gender,
        emailControl: email?.email,
        phoneControl: phone?.number,
        CEPControl: address?.zipCode,
        addressControl: address?.area,
        numberControl: address?.number,
        complementControl: address?.complement, // Adiciona o complemento
        neighborhoodControl: address?.neighborhood,
        stateControl: address?.stateCode,
        cityControl: address?.cityCode,
        rankingConfirmarionControl: this.partner.participatesInRanking
      });

      if (address?.stateCode) {
        this.getCities();
      }
    }
  }

  back() {
    this.location.back();
  }

  onSubmit(): void {
    if (this.partnerDataformGroup.valid) {
      const partnerData = this.buildPayloadPartnerData(this.partnerDataformGroup);
      this.memberService.update(partnerData, this.partner.id).subscribe({
        next: () => {
          this.saveAddress();
        }
      });
    } else {
      console.log('Form is invalid');
    }
  }

  public saveAddress() {
    const data = this.buildPayloadPartnerDataAddress(this.partnerDataformGroup);
    if (data?.id) {
      this.memberService.updateAddress(this?.partner?.addresses[0]?.id, data).subscribe({
        next: () => {
          this.getMemberUpdated();
        }
      });
    } else {
      this.getMemberUpdated();
    }
  }

  public getMemberUpdated() {
    this.memberService.getMemberPublicInfo(this.partner.cpf).subscribe(item => {
      this.router.navigate(['/dashboard']);
    });
  }

  private buildPayloadPartnerData(formGroup: FormGroup): any {
    let email = this.partner?.emails.find((email: any) => email.isPrincipal);
    let phone = this.partner?.phones.find((email: any) => email.isPrincipal);

    return {
      name: formGroup.get('name')?.value,
      cpf: formGroup.get('cpfControl')?.value,
      birthDate: formGroup.get('birthDate')?.value.split('/').reverse().join('-'),
      gender: formGroup.get('genderControl')?.value,
      participatesInRanking: formGroup.get('rankingConfirmarionControl')?.value,

      phones: [
        {
          id: phone.id, description: null, isPrincipal: true, isActive: true, isDeleted: false,
          ddd: null, number: formGroup.get('phoneControl')?.value, createdAt: phone.createdAt, updatedAt: phone.updatedAt
        }
      ],
      emails: [{
        id: email.id, description: null, isPrincipal: true, isActive: true, isDeleted: false,
        email: formGroup.get('emailControl')?.value.toLowerCase(), // Converte o email para minúsculas
        createdAt: email.createdAt, updatedAt: email.updatedAt
      }]
    };
  }

  private buildPayloadPartnerDataAddress(formGroup: FormGroup): any {
    return {
      id: this?.partner?.addresses[0]?.id,
      zipCode: formGroup.get('CEPControl')?.value,
      area: formGroup.get('addressControl')?.value,
      number: formGroup.get('numberControl')?.value,
      complement: formGroup.get('complementControl')?.value, // Adiciona o complemento
      neighborhood: formGroup.get('neighborhoodControl')?.value,
      stateCode: formGroup.get('stateControl')?.value,
      cityCode: formGroup.get('cityControl')?.value
    };
  }

  getCities() {
    let state = this.partnerDataformGroup.value.stateControl;
    if(state ){
      this.commonService.getCity(state).subscribe(cities => {
        this.cityOptions = cities.map((obj: any) => ({ code: obj.id, name: obj.name }));
      });
    }
  }
}
