<div class="upload-container">
    <div class="form-group">
        <label for="fileInput" [style.color]="labelColor">Escolha um arquivo:</label>
        <input type="file" id="fileInput" (change)="onFileChange($event)" />
    </div>
    <div *ngIf="usersList && usersList.length > 0" class="col-4 my-2">
        <label for="userId" class="form-label">
            Usuário
        </label>
        <select class="form-select" [(ngModel)]="selectedUser" id="userId">
            <option *ngFor="let user of usersList" [value]="user.id">
                {{ currentUser.id === user.id ? 'Usuário - ' : 'Dependente - ' }}{{ user.name }}
            </option>
        </select>
    </div>
    <app-button 
        class="my-1" 
        id="sendBtn" [buttonOptions]="sendBtn" 
        type="submit" [disabled]="!selectedFile"
        (buttonClick)="onClick()">
    </app-button>
</div>