
<div class="modal fade" id="{{modalId()}}" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true"
  [attr.data-bs-backdrop]="backdropType">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Carteirinha QR Code</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="onCancel()"></button>
      </div>
      <div class="modal-body">
        <div class="text-center d-flex align-items-center justify-content-center flex-column">
          @if (cardNumberHex) {
          <qrcode #qrCode [qrdata]="cardNumberHex" [width]="150" elementType="svg" class="d-flex my-3"></qrcode>
          }
          <span class="h6 mt-3">Última atualização: <b>{{ openDateTime }}</b></span>
          <!-- <h2>Escaneie o QR Code para acessar sua carteirinha.</h2> -->
        </div>
      </div>
    </div>
  </div>
</div>