<div class="container p-4 p-md-5 col-md-8">
    <div class="delete-account-main-title my-4">
      {{title}}
    </div>
    <form>
      <app-checkbox
        [id]="'confirmDeletionId'"
        [label]="'Confirmar exclusão da conta'"
        [formControl]="confirmDeletionControl"
        (change)="onCheckboxChange()"
      ></app-checkbox>
    </form>
  
    <p *ngIf="showDeletionMessage">
      Ao marcar esta opção e clicar em "Excluir Conta", sua conta será permanentemente excluída e todos os seus dados serão removidos. Esta ação não pode ser desfeita.
    </p>
  
    <div class="d-flex justify-content-end m-delete-account">
      <app-button class="my-2 mx-2" id="btn-cancel"
        [buttonOptions]="btnCancelOptions"
        (buttonClick)="cancel()">
      </app-button>
      <app-button class="my-2 mx-2" style="color: white;" id="btn-delete"
        [buttonOptions]="btnDeleteOptions"
        (buttonClick)="deleteAccount()">
      </app-button>
    </div>
  </div>