<div class="app-cards-resgatar" [class.pb-5]="data() && data().length == 0">
    @if (title()) {
    <h4 class="main-subtitle" [style.color]="labelColor">
        {{ title() }}
    </h4>
    }
    <div class="cards">
        @for (item of data(); track $index) {
        <div (click)="goTo(item.link)" [class.click]="item.link !== '' && item.link !== null" class="bg-white card">
            @if (item.inactiveItem == true) {
            <div class="notClick"></div>
            }
            @if (!item.topIcon) {
            <div class="header" (click)="logdata()">
                <img class="icon" [src]="item.image" alt="User image">
                <h3 class="title">{{ item.title }}</h3>
            </div>
            }
            @if (item.topIcon) {
            <div class="header-top" (click)="logdata()">
                <img class="icon" [src]="item.image" alt="User image">
                <h3 class="title">{{ item.title }}</h3>
            </div>
            }
            <div class="content">
                <p>{{ item.content }}</p>
            </div>
            @if (item.value != '' || item.point != '' || item.cupom != '') {
            <div class="d-flex align-items-center justify-content-between mt-3">
                @if (item.cupom != '' && item.cupom != null) {
                <div class="coupon">
                    <p>
                        Cupom: <span>{{ item.cupom }}</span>
                    </p>
                </div>
                }
                <div class="points">
                    @if (item.point != '' && typeContent() == 'EXPERIENCE') {
                    <div class="point color-primary"
                        [ngStyle]="{'text-decoration': item.inactiveItem == true ? 'line-through' : '' }">
                        {{ item.point }} pontos
                    </div>
                    }
                    @if (item.value != '' && typeContent() == 'EXPERIENCE') {
                    <div class="buy">
                        @if(item.point != '' && typeContent() == 'EXPERIENCE') {
                        <span>ou</span>
                        }
                        <p>Desconto: {{item.value}}</p>
                    </div>
                    }
                </div>
            </div>
            }
            <!-- @if (item.valid != '') {
            <div class="valid">
                <p>{{ item.valid }}</p>
            </div>
            } -->
            <div class="buttons">
                @if (item.point != '' && item.rescue) {
                <app-button class="app-button button"
                    [ngStyle]="{'width': item.point != '' ? item.value != '' ? '49%' : '100%' : '100%'}"
                    [style]="'--bs-btn-padding-x: 1.4em; width: 100%;'" [buttonOptions]="checkInBtn"
                    [disabled]="item.inactiveButton" (buttonClick)="rescueExperience(item)">
                </app-button>
                }
                <!-- @if (item.value != '') {
                <app-button class="app-button button"
                    [ngStyle]="{'width': item.value != '' ? item.point != '' ? '49%' : '100%' : '100%'}"
                    [style]="'--bs-btn-padding-x: 1.4em; width: 100%;'" [buttonOptions]="buyBtn"
                    [disabled]="item.inactiveButton">
                </app-button>
                } -->
            </div>

        </div>
        }
        @if (data() && data().length == 0) {
        <div>
            <p>
                Não existem cupons para serem exibidos.
            </p>
        </div>
        }
    </div>
</div>