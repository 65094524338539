<section class="beneficios-section" [ngStyle]="styles" *ngIf="styles">
  <div class="container p-4">
    <h2 class="section-title">{{ title }}</h2>

    <div class="row beneficios-table flex-nowrap">
      <div class="table table-responsive px-0 my-0">
        <table class="table table-striped">
          <thead>
            <tr>
              <th class="align-middle header">BENEFÍCIOS</th>
              @for(benefitPlan of benefitsTableData; track benefitPlan.title; let idx = $index){
              <th class="text-center align-middle header beneficio" [id]="'benefit-' + idx">{{ benefitPlan.title }}</th>
              }
            </tr>
          </thead>
          <tbody>
            @for(benefitItem of benefitsList; track benefitItem.benefitName; let idx = $index) {
              <tr>
                <td class="align-middle">{{ benefitItem.benefitName }}</td>
                @for(benefitPlan of benefitsTableData; track benefitPlan.title; let idx = $index){
                  <td class="text-center align-middle">
                    <i class="bi" [ngClass]="benefitsCheck(benefitPlan, benefitItem.id)"></i>
                  </td>
                }
              </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>