import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivateFn } from '@angular/router';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { throwError } from 'rxjs';
import { AlertService, AlertType } from '../services/alert.service';

export const authGuard: CanActivateFn = (route, state) => {
  const userService = inject(UserService);
  const authService = inject(AuthService);
  const router = inject(Router);
  const alertService = inject(AlertService);
  const hasMenuEveryOne = localStorage.getItem('menuEveryoneCard') ? localStorage.getItem('menuEveryoneCard') : false;


  const currentUser = userService.currentUserValue ?? {} ;
  const token = authService.getToken() ?? '' ;

  if (token && authService.isTokenExpired(token)) {
    authService.logout(state.url);
    return throwError(() => new Error('Token expired'));
  }

  if(!hasMenuEveryOne && state.url === '/cartao-todos'){
    alertService.showAlert(AlertType.WARNING, 'Página não disponível.');
    router.navigate(['/']);
    return false;
  }


  if (
    Object.keys(currentUser)?.length &&
    token
  ){
    return true;
  }

  authService.logout(state.url);
  return false;
};
