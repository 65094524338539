import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from './error-handler.service';
import { Observable } from 'rxjs';
import { BaseService } from './base/base.service';

@Injectable({
  providedIn: 'root'
})
export class SubstituteService extends BaseService {

   private env = environment;

    constructor(
        http: HttpClient,
        errorHandlerService: ErrorHandlerService) {
        super(http, errorHandlerService);
      }

      findSubstituteByCpf(cpf: any): Observable<any[]> {
          return this.get<any[]>(`${this.env.publicApiSocioUrl}v1/socio/substitute-checkin/by-substitute-cpf/${cpf}`);
      }

      createSubstituteCheckin(substitute: any): Observable<any> {
        return this.post<any>(`${this.env.publicApiSocioUrl}v1/socio/substitute-checkin/create-substitute-checkin`, substitute);
      }
}
