@if (showCookieBanner){
<div class="cookie-banner row d-md-flex text-center" [ngStyle]="styles">
    <div class="col-lg-10 col-md-12 col-xs-12 col-sm-12">
        <p>Usamos cookies para armazenar informações sobre como você usa o nosso site e as páginas que visita. Tudo para
            tornar sua experiência a mais agradável possível. Ao clicar em Eu Concordo, você consente com a utilização
            de cookies.</p>
    </div>
    <div class="col-lg-2 col-md-12 col-xs-12 col-sm-12">
        <app-button id="back-desktop" [onlyIcon]="true" [buttonOptions]="buttonOptions" (buttonClick)="acceptCookies()"></app-button>
    </div>
</div>
}