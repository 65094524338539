<footer class="footer" *ngIf="showSection" [ngStyle]="styles">
  <div class="container main-container px-4 py-3 d-flex flex-wrap flex-column flex-md-row">
    <div
      class="section logo-section d-flex justify-content-center flex-column align-items-center align-items-md-start pb-4 pb-md-0">
      <img [src]="imageLogo" alt="Logo" class="logo" *ngIf="showLogo">
    </div>
    <div class="section d-flex flex-column">
      <h3 class="hl pb-2">Atendimento</h3>
      <p *ngFor="let phone of phoneNumbers" role="button">
        <a href="tel:+{{ phone }}" class="text-decoration-none">
          <i class="bi bi-telephone-fill pr-1 icons"></i>{{ phone}}
        </a>
      </p>
      <p *ngFor="let email of emails" role="button">
        <a href="mailto:{{ email.email}}" class="text-decoration-none">
          <i class="bi bi-envelope-fill pr-1 icons"></i>{{ email.email}}
        </a>
      </p>
    </div>
    <div class="section sobre-nos-section">
      <h3 class="hl">Sobre Nós</h3>
      <p *ngIf="showCNPJ">CNPJ: {{ cnpj | cnpj }}</p>
      <p>{{ address }}</p>
      <p><a routerLink="/regulamento" role="button" *ngIf="showRegulation">Regulamento</a></p>
      <p *ngIf="privacyPolicy"><a [routerLink]="['/politica-de-privacidade']" role="button">Política de Privacidade</a></p>
      <p *ngIf="privacyPolicy"><a [routerLink]="['//faq']" role="button">Faq</a></p>
      
    </div>
    <div class="section redes-sociais-section" *ngIf="showSocialNetwork">
      <h3 class="hl">Segue nas Redes Sociais</h3>
      <div class="social-links">
        <a *ngFor="let network of clubSocialNetwork" mat-icon-button [href]="network.href" target="_blank">
          <i [class]="network.class"></i>
        </a>
      </div>
    </div>
    <div class="section arrow-up-section d-flex align-items-end align-items-md-start">
      <button class="button-arrow-up" (click)="scrollToTop()">
        <i class="bi bi-arrow-up"></i>
      </button>
    </div>
  </div>
  <div class="copyright pb-2">
    <p class="h6">{{finalSentence}}</p>
  </div>
</footer>