import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from './error-handler.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GuestService extends BaseService {

  private env = environment;

  constructor(
      http: HttpClient,
      errorHandlerService: ErrorHandlerService) {
      super(http, errorHandlerService);
    }

    findGuestByCpf(cpf: any): Observable<any[]> {
        return this.get<any[]>(`${this.env.publicApiSocioUrl}v1/socio/guest-checkin/by-guest-cpf/${cpf}`);
    }

    createGuestCheckin(guest: any): Observable<any> {
      return this.post<any>(`${this.env.publicApiSocioUrl}v1/socio/guest-checkin/create-guest-checkin`, guest);
    }
}
