import { CommonModule } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';
import { PartnerService } from 'src/app/core/services/partner.service';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { LayoutConfigService } from 'src/app/shared/services/layout-config.service';

interface CarouselItem {
  title: string;
  conteudo: string;
  descricao: string;
  src: string;
  discount: number;
  link: string;
}

@Component({
  selector: 'app-section-carousel-beneficios',
  standalone: true,
  imports: [CommonModule, AppButtonComponent],
  templateUrl: './section-carousel-beneficios.component.html',
  styleUrl: './section-carousel-beneficios.component.scss'
})
export class SectionCarouselBeneficiosComponent {

  styles: any;
  public classes: any;
  chunkedItems: CarouselItem[][] = [];
  chunkSize = 4;
  isMobile = false;
  showIndicators = false;
  layoutVersion = this.layoutConfigService.getLayoutVersion();
  title = '';
  subTitle = '';
  showSubtitle = false;

  items = [];

  constructor(
    private layoutConfigService: LayoutConfigService,
    private partnerService: PartnerService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.setCardSize(window.innerWidth);
    this.getAllPartners();
    this.getParnersStyles();
    this.styles = {
      '--border-radius': this.layoutVersion === 1 ? '15px' : '0px'
    };
    this.classes = {
      'align-items-center': this.layoutVersion === 1,
      'align-items-start': this.layoutVersion === 2
    };
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.setCardSize((event.target as Window).innerWidth);
    this.chunkItems();
  }

  getAllPartners() {
    this.partnerService.getAllPartners().subscribe((partners: any) => {
      partners.content.forEach((element: any) => {
        element.description = element.description.replace(/\n/g, "<br />");
      });
  
      this.items = partners.content.map((partner: any) => ({
        title: partner.partnerName,
        conteudo: partner.description,
        descricao: partner.planName,
        src: this.commonService.getImageStr(partner.document?.id),
        discount: this.formatDiscount(partner.isPercentualDiscount, partner.discount),
        link: partner.link
      }));
  
      this.chunkItems();
    });
  }

  private formatDiscount(isPercentual: boolean, discount: number | null): string {
    if (discount == null || discount === 0) {
      return '0'; // Retorna 0 caso o desconto seja nulo ou zero
    }
    return isPercentual
      ? `${discount}%`
      : `R$ ${discount.toFixed(2).replace('.', ',')}`;
  }
  

  setCardSize(innerWidth: number) {
    this.isMobile = false;
    switch (true) {
      case innerWidth < 768:
        this.chunkSize = 1;
        this.isMobile = true;
        break;
      case innerWidth >= 768 && innerWidth < 990:
        this.chunkSize = 2;

        break;
      case innerWidth >= 990 && innerWidth < 1200:
        this.chunkSize = 3
        break;
      case innerWidth >= 1200:
        this.chunkSize = 4
        break;

      default:
        break;
    }
  }

  chunkItems() {
    this.chunkedItems = [];

    for (let i = 0; i < this.items.length; i += this.chunkSize ) {
      this.chunkedItems.push(this.items.slice(i, i + this.chunkSize ));
    }

    this.showIndicators = this.chunkedItems.length > 1;
    
  }


  getParnersStyles() {
    this.partnerService.getParnersStyles().subscribe((styles: any) => {
      let partnerStylesResponse = styles;
      this.styles = {
        carouselType: partnerStylesResponse.carouselFixed ? null : 'carousel',
        carouselInterval: partnerStylesResponse.carouselMovementTime,
        '--section-title-font-size': this.commonService.getRemFontSize(partnerStylesResponse?.sectionTitleFontSize, this.isMobile),
        '--section-title-color': partnerStylesResponse?.sectionTitleColor,
        '--show-section': partnerStylesResponse?.showSection ? 'block' : 'none',
        '--subtitle-font-size': this.commonService.getRemFontSize(partnerStylesResponse?.subtitleFontSize, this.isMobile),
        '--subtitle-color': partnerStylesResponse?.subtitleColor,
        '--show-subtitle': partnerStylesResponse?.showSubtitle ? 'block' : 'none',
        '--background-color': partnerStylesResponse?.backgroundColor,
        '--background-image': partnerStylesResponse?.backgroundIsImage ? `url(${this.commonService.getImageStr(partnerStylesResponse?.document?.id)})` : 'none',
        '--border-radius': this.layoutVersion === 1 ? '15px' : '0px',
        '--side-buttons-color': partnerStylesResponse?.sideButtonsColor,
        '--side-buttons-border-color': partnerStylesResponse?.sideButtonsBorder ? partnerStylesResponse?.sideButtonsBorderColor : 'none',
        '--side-buttons-icon-color': partnerStylesResponse?.sideButtonsIconColor == 'true' ? 'invert(1)' : 'invert(0)',
        '--carousel-indicators-color': partnerStylesResponse?.mainButtonColor,
      }

      this.title = partnerStylesResponse?.sectionTitle;
      this.subTitle = partnerStylesResponse?.subtitleText;
      this.showSubtitle = partnerStylesResponse.showSubtitle;
    });
  }

  openLink(parceiro: any): void {
    console.log('parceiro', parceiro);
    
    if (parceiro.link) {
      window.open(parceiro.link, '_blank');
    }
  }

}
