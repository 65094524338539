<div class="container p-4 p-md-0 col-md-8 position-relative">
    <div class="d-flex justify-content-end back-buttom">
        <app-button id="back-desktop" [onlyIcon]="true" [isIcon]="true" [left]="true" [iconName]="'bi bi-arrow-left'"
            [buttonOptions]="buttonBackOptions" (buttonClick)="back()">
        </app-button>
    </div>
    <div class="edit-plan-main-title my-4">
        {{title}}
    </div>
    <form>
        <app-checkbox
            [id]="'invoiceRecurrenceId'"
            [label]="'Renovação Automática'"
            [formControl]="invoiceRecurrenceControl"
            (change)="onCheckboxChange()"
            ></app-checkbox>

               
    </form>

    <p *ngIf="showRenewalMessage">
        Seu plano poderá ser renovado automaticamente. Caso deseje o cancelamento da renovação, entre em contato com o atendimento.
      </p> 

    <div class="d-flex justify-content-end m-edit-plan">
        <app-button class="my-2 mx-2" id="btn-save"
            [buttonOptions]="btnSaveOptions"
            (buttonClick)="save()">
        </app-button>
    </div>
</div>
