import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';
import { EveryoneCardService } from 'src/app/core/services/everyone-card.service';
import { MemberService } from 'src/app/core/services/member.service';
import { UserService } from 'src/app/core/services/user.service';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { UserModel } from 'src/app/shared/models/userModel';

@Component({
  selector: 'app-everyone-card',
  standalone: true,
  imports: [AppButtonComponent],
  templateUrl: './everyone-card.component.html',
  styleUrl: './everyone-card.component.scss'
})
export class EveryoneCardComponent implements OnInit {

  labelColor = 'white';
  memberData:any;
  user: UserModel;

  pageTitle = localStorage.getItem('labelEveryoneCard') ? localStorage.getItem('labelEveryoneCard') : "Cartão de Todos" ;

  buttonBackOptions = {
    buttonText: 'Voltar',
    buttonSize: 'btn btn-md px-3 btn-secondary',
    borderRadius: '25px',
  };

  buttonAdesaoOptions = {
    buttonText: 'Solicitar Adesão',
    buttonSize: 'btn btn-md px-3 btn-primary',
    borderRadius: '25px',
  };

  constructor(
    private memberService: MemberService,
    private datePipe: DatePipe,
    private router: Router,
    private everyoneCardService: EveryoneCardService,
    private alertService: AlertService,
    private userService: UserService
  ){this.user = this.userService.currentUserValue as UserModel;
   
  }

  ngOnInit(): void {
    this.memberService.getMemberDashboardData(this.user.id).subscribe(res=>{
      this.memberData = res;
    })
  }

 back(): void {
  window.history.back();
 }

 solicitarAdesao(){
  let adesaoPayload : any = {
    document: this.memberData?.cpf,
    name: this.memberData?.name,
    gender: this.memberData?.gender === 'F' ? 2 : 1,
    birthDate: this.datePipe.transform(this.memberData?.birthDate, 'dd/MM/yyyy'),
    email: this.memberData?.emails[0]?.email,
    phone: this.memberData?.phones[0]?.number,
    area: this.memberData?.addresses[0]?.area,
    number: this.memberData?.addresses[0]?.number,
    neighborhood: this.memberData?.addresses[0]?.neighborhood,
    cityName: this.memberData?.addresses[0]?.cityName,
    stateName: this.memberData?.addresses[0]?.stateName,
    complement: this.memberData?.addresses[0]?.complement,
    zipCode: this.memberData?.addresses[0]?.zipCode,
    clubName: localStorage.getItem('clubName'),
    clubCnpj: localStorage.getItem('clubCnpj')
  }

  this.everyoneCardService.sendAdesaoData(adesaoPayload).subscribe({
    next: (res:any) => {
      this.alertService.showAlert(AlertType.SUCCESS, 'Adesão realizada com sucesso.');
    }
  })



 }


}
