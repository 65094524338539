import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { Location } from '@angular/common';

import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { AppInputComponent, InputType } from 'src/app/shared/components/app-input/app-input.component';

@Component({
  selector: 'app-update-password',
  standalone: true,
  imports: [CommonModule, AppInputComponent, ReactiveFormsModule, AppButtonComponent, RouterLink],
  templateUrl: './update-password.component.html',
  styleUrl: './update-password.component.scss'
})
export class UpdatePasswordComponent implements OnInit, OnDestroy {
  InputTypeEnum = InputType;

  title = 'Alterar Senha';
  subtitle = 'A Senha deve possuir pelo menos 6 caracteres e no máximo 12.';
  private _destroy$ = new Subject<void>();

  updatePassFormGroup = new FormGroup({
    newPassControl: new FormControl<string>('', [Validators.required, Validators.minLength(6)]),
    confirmNewPassControl: new FormControl<string>('', [Validators.required, Validators.minLength(6)]),
  });

  btnSaveOptions = {
    buttonText: 'Salvar',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm py-2 px-5 btn-primary',
    btnDisabledBackground: '#f2e2b2',
    btnDisabledTextColor: '#000000'
  };

  buttonOptions = {
    buttonText: 'Voltar',
    buttonBorderWidth: '1px',
    buttonSize: 'btn btn-md px-3',
    borderRadius: '25px'
  };

  passwordValid = false;

  constructor(
    private alertService: AlertService,
    private router: Router,
    private authenticationService: AuthService,
    private location: Location,
  ) { }

  ngOnInit(): void {
    this.updatePassFormGroup.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.passwordValid = this.updatePassFormGroup.get('newPassControl')?.value ===
                             this.updatePassFormGroup.get('confirmNewPassControl')?.value;
      });


  }



  onSubmit(): void {
      this.changePasswordLoggedIn();
  }

  back() {
    this.location.back();
  }

  private changePasswordLoggedIn(): void {
    const newPassword = this.updatePassFormGroup.get('newPassControl')?.value;
    const passwordConfirmation = this.updatePassFormGroup.get('confirmNewPassControl')?.value;
    const token = this.authenticationService.getToken();

    if (!newPassword || !passwordConfirmation || !token) {
      console.error('Missing required data for password change');
      return;
    }

    this.authenticationService.changePasswordLoggedIn(newPassword, passwordConfirmation, token)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: () => {
          this.alertService.showAlert(AlertType.SUCCESS, 'Senha atualizada com sucesso!');
          this.router.navigate(['/dashboard']);
        },
        error: (err) => {
          console.error('Erro ao alterar a senha', err);
          if (!err.error.message) {
            this.alertService.showAlert(AlertType.DANGER, 'Ocorreu um erro ao atualizar a senha.');
          }
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
