<div class="container px-4 py-5" [ngStyle]="style">
    <div class="d-flex justify-content-end">
        <app-button id="back-desktop" [onlyIcon]="true" [isIcon]="true" [left]="true" [iconName]="'bi bi-arrow-left'"
            [buttonOptions]="backBtn" (buttonClick)="back()">
        </app-button>
    </div>
    <div class="convide-amigo">
        <div class="headerPage">
            <h1 class="main-title">
                Convidar amigo
            </h1>
            <p class="subtitle">
                Selecione a forma de convite para trazer um amigo para o Sócio Torcedor
            </p>
            <div class="filters">
                <button (click)="typeActive = 'email'" class="btn-primary"
                    [ngClass]="{'activeButton': typeActive === 'email'}">
                    <mat-icon svgIcon='envelope'></mat-icon>
                    <p>Email</p>
                </button>
                <button (click)="typeActive = 'redes-sociais'"
                    [ngClass]="{'activeButton': typeActive === 'redes-sociais'}">
                    <mat-icon svgIcon='share'></mat-icon>
                    <p>Redes Sociais</p>
                </button>
            </div>
        </div>
        <div class="email" *ngIf="typeActive == 'email'">
            <div class="dadosEmail">
                <p class="subtitle">
                    Um email será enviado para o convidado com todas as instruções para fazer parte do Sócio Torcedor
                </p>
                <form [formGroup]="filterGroup" class="col-12">
                    <app-input label="Email do convidado" [id]="'filterInputId'"
                        [placeholder]="'Insira o email do amigo que será convidado'"
                        formControlName="filterControl"></app-input>
                </form>
                <div class="buttons justify-content-end justify-content-md-between">
                    <app-button [style]="'padding-right: 4rem; padding-left: 4rem;'" class="mx-2 my-1"
                        id="confirmModalBtn" [buttonOptions]="sendBtn" (buttonClick)="sendInvite()">
                    </app-button>
                </div>
            </div>
            @if (convitesEnviadosPerPage.length > 0) {
            <div class="convitesEnviados">
                <h2>Convites enviados</h2>
                <div class="listaEnviados">
                    <div class="title bg-primary text-white">
                        <p class="email">Email</p>
                        <p class="dataEnvio">Data Envio</p>
                        <p class="email">Sócio</p>
                    </div>
                    <div class="item " *ngFor="let item of convitesEnviadosPerPage; let i = index"
                        [ngClass]="{'bgGray': i % 2 != 0}">
                        <p class="email">{{ item.emailRegistered ? item.emailRegistered : item.emailIndicated  }}</p>
                        <p class="dataEnvio">{{ item.createdAt | date: 'dd/MM/yyyy' }}</p>
                        <p class="email">{{ item.emailRegistered ? 'Sim' : 'Não' }}</p>
                    </div>
                </div>
                <div class="pagination">
                    <button (click)="prevPage()" [disabled]="currentPage === 1">
                        <i class="bi bi-arrow-left-circle-fill"></i>
                    </button>
                    <ng-container *ngFor="let page of [].constructor(totalPages); let i = index">
                        <button (click)="goToPage(i + 1)" [class.active]="i + 1 === currentPage">
                            {{ i + 1 }}
                        </button>
                    </ng-container>
                    <button (click)="nextPage()" [disabled]="currentPage === totalPages">
                        <i class="bi bi-arrow-right-circle-fill"></i>
                    </button>
                </div>
            </div>
            }
        </div>
        <div class="redes-sociais" *ngIf="typeActive == 'redes-sociais'">
            <p class="subtitle">
                Utilize o link da rede social que será utilizada para convidar, clique para copiar e colar
            </p>
            <div class="buttons">
                <div class="item">
                    <label for="whatsapp">Whatsapp</label>
                    <button (click)="copyText(link + 'f2be14b8-3d5a-40a7-bf65-3449573f58c8')">
                        <p><i class="fa-brands fa-whatsapp"></i>{{link}}f2be14b8-3d5a-40a7-bf65-3449573f58c8</p>
                        <img src="/assets/svg/copy.svg" alt="share icon">
                    </button>
                </div>
                <div class="item">
                    <label for="facebook">Facebook</label>
                    <button (click)="copyText(link + '1e092aba-bc14-4d52-ae6a-2bf319b8f7b3')">
                        <p><i class="fa-brands fa-facebook"></i>{{link}}1e092aba-bc14-4d52-ae6a-2bf319b8f7b3</p>
                        <img src="/assets/svg/copy.svg" alt="share icon">
                    </button>
                </div>
                <div class="item">
                    <label for="instagram">Instagram</label>
                    <button (click)="copyText(link + 'aafd9194-1329-4c40-9b36-d8f9acf10688')">
                        <p><i class="fa-brands fa-instagram"></i>{{link}}aafd9194-1329-4c40-9b36-d8f9acf10688</p>
                        <img src="/assets/svg/copy.svg" alt="share icon">
                    </button>
                </div>
                <div class="item">
                    <label for="twitter">Twitter</label>
                    <button (click)="copyText(link + '4ba35f44-6233-4f56-bbab-dd69b4a6d04f')">
                        <p><i class="fa-brands fa-twitter"></i>{{link}}4ba35f44-6233-4f56-bbab-dd69b4a6d04f</p>
                        <img src="/assets/svg/copy.svg" alt="share icon">
                    </button>
                </div>
                <div class="item">
                    <label for="tiktok">Tiktok</label>
                    <button (click)="copyText(link + 'aad362b1-261f-4062-9a9d-c1f3b83a1ea9')">
                        <p><i class="fa-brands fa-tiktok"></i>{{link}}aad362b1-261f-4062-9a9d-c1f3b83a1ea9</p>
                        <img src="/assets/svg/copy.svg" alt="share icon">
                    </button>
                </div>
            </div>
            @if (indicacoesRealizadas.length > 0) {
            <div class="convitesEnviados">
                <h2>Indicações Realizadas</h2>
                <div class="listaEnviados">
                    <div class="title bg-primary d-flex">
                        <p class="email">Nome</p>
                        <p class="status">Origem (Rede Social)</p>
                    </div>
                    <div class="item" *ngFor="let item of indicacoesRealizadas; let i = index"
                        [ngClass]="{'bgGray': i % 2 != 0}">
                        <p class="email">{{ item?.memberGuest?.name }}</p>
                        <p class="status">{{ item?.socialNetwork?.name }}</p>
                    </div>
                </div>
            </div>
            }
        </div>
    </div>
</div>
