<nav [style]="menuStyles"
  class="navbar navbar-expand-lg navbar-light bg-light d-flex fixed-top justify-content-between py-2 flex-nowrap">
  <div class="container main-container">
    @if (logoStyles) {
    <app-logo-text role="button" (click)="navigationLogo()" [inputStyle]="logoStyles">
    </app-logo-text>
    }

    <!--  Visão desktop: Mostra do md pra cima -->
    <div class="d-none d-md-block nav-items align-content-center custom-scrollbar">
      <nav class="menu d-flex" [ngStyle]="menuStyles">
        @for (menuItem of headerItems(); track $index) {

        @if(activeSectionsSearched){
        <div class="nav-item d-grid px-3 pt-1 cursor-pointer text-center text-nowrap"
          [class.disabled]="!activeSections[menuItem.id] && isLoggedIn() || isLoggedIn() && userBlocked"
          [class.active]="menuItem.id === selectedMenuItem" (click)="navigationHeaderItems(menuItem.id)">
          <i *ngIf="menuItem.id === selectedMenuItem" class="bi bi-star-fill mb-1"></i>
          <span class="align-content-end">
            {{ menuItem.name }}
          </span>
        </div>
        }

        <!-- <div class="nav-item d-grid px-3 pt-1 cursor-pointer text-center"
            [class.disabled]="isLoggedIn() && userBlocked"
            [class.active]="menuItem.id === selectedMenuItem"
            (click)="navigationHeaderItems(menuItem.id)">
            <i *ngIf="menuItem.id === selectedMenuItem" class="bi bi-star-fill mb-1"></i>
            <span class="align-content-end">
              {{ menuItem.name }}
            </span>
          </div> -->

        }


      </nav>
    </div>

    <!-- Sidebar: Visão mobile: Escondido do md pra cima -->
    @if (logoStyles && !isBlocked) {
    <div class="d-flex d-md-none">
      @if (isLoggedIn()) {
      <notification-dropdown class="pe-3" [styles]="menuStyles" [notifications]="notifications"
        (markAllNotificationsAsRead)="markAllNotificationsAsRead()"></notification-dropdown>
      }
      <app-user-side-bar [menuItems]="headerItems()" [userInfo]="userInfo" [isLoggedIn]="isLoggedIn()"
        [selectedMenuItem]="selectedMenuItem" [inputStyles]="menuStyles" [userBlocked]="userBlocked"
        [logoStyles]="logoStyles" [buttonStyles]="importantStyles"
        (selectedMenuItemChange)="navigationHeaderItems($event)">
      </app-user-side-bar>
    </div>
    }

    <!--  Visão desktop: Mostra do md pra cima -->
    @if (!isLoggedIn()) {
    <div class="d-none d-md-flex align-content-center">
      <div class="d-flex flex-wrap align-content-center justify-content-center">

        <app-button class="mx-2 my-1 hover-button" id="cadastro-socio" [buttonOptions]="buttonGonnaBePartnerOptions"
          routerLink="/cadastro-socio">
        </app-button>

        <app-button class="mx-2 my-1 hover-button-enter" id="header-login" [buttonOptions]="buttonLoginOptions"
          routerLink="/login">
        </app-button>

      </div>
    </div>
    } @else {
    <div class="d-flex d-none d-md-flex" *ngIf="hasUserInfo">
      <div class="vr"></div>
      <div class="d-flex ms-2 justify-content-end user-area">
        <notification-dropdown [styles]="menuStyles" [notifications]="notifications"
          (markAllNotificationsAsRead)="markAllNotificationsAsRead()"></notification-dropdown>
        <user-dropdown [styles]="menuStyles" [userInfo]="userInfo" [userBlocked]="userBlocked"></user-dropdown>
      </div>
    </div>
    }
  </div>

</nav>